import React from "react";
import './ShowRoom.scss';
import './ReadMorePopup.scss';
import { Button } from "react-bootstrap";
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import cards from '../../Assets/data/cards'


function ReadMorePopup(props) {

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        timeout={300}
        key={props.show}
        classNames="fade">
        <div>
          {props.show &&
            <div className="wrapper">
              <button className="goBack" onClick={props.onClosePopup}>
                <ArrowLeft className="arrowRight" size={24} /> Go back
              </button>

              <section className="readMorePopupContainer"> <header>
                <img src={process.env.PUBLIC_URL + '/imgs/' + cards[props.id].dirname + '/motiv.png'} className="motivImg" alt={cards[props.id].name + " image"} />
                <div >
                  <h1>{cards[props.id].name}</h1>
                  <p className="yearText">{cards[props.id].year}</p>
                  <hr />
                </div>
              </header>
                <section className="description">
                  <h2 className="specificationsTitle">Description</h2>
                  <p>{cards[props.id].description}</p>
                </section>
                <section className="designerInfo">
                  <h2>Designer</h2>
                  <p className="textUnderTitle">{cards[props.id].designer.name}</p>

                  <p>{cards[props.id].designer.description}</p>

                  {
                    (cards[props.id].designer.url) ?
                      <Button className="button" rel="nofollow" onClick={() => (window.open(cards[props.id].designer.url.link, '_blank').focus())}>
                        {cards[props.id].designer.url.text} <ArrowRight className="arrowRight" size={19} />
                      </Button>
                      : ""
                  }

                </section>
                <section className="producerInfo">
                  <h2>Producer</h2>
                  <p className="textUnderTitle">{cards[props.id].producer.name}</p>

                  <p>{cards[props.id].producer.description}</p>

                  {
                    (cards[props.id].producer.url) ?
                      <Button className="button" rel="nofollow" onClick={() => (window.open(cards[props.id].producer.url.link, '_blank').focus())}>
                        {cards[props.id].producer.url.text} <ArrowRight className="arrowRight" size={19} />
                      </Button>
                      : ""
                  }
                </section>
                {(cards[props.id].interviews ?? []).map(i => (
                  <div key={i.url} className='interview'>
                    <h2>{i.rubric}</h2>
                    <p className="textUnderTitle">{i.info}</p>
                    <iframe title={i.rubric} src={i.url} width="fit-content" height="100%" frameBorder="0" allow="autoplay; fullscreen"></iframe>
                  </div>
                ))
                }

              </section>


            </div>
          }
        </div>

      </CSSTransition>
    </SwitchTransition>

  );

}

export default ReadMorePopup;
