import React from "react";
import { BsX } from "react-icons/bs";
import { Link } from "react-router-dom";

const QuizExitModal = ({ setToggleExitModal, saveQuiz }) => {
  return (
    <div className="leave-modal">
      <div className="leave-card">
        <p className="leave-title">Before you leave!</p>
        <div className="leave-buttons">
          <button
            className="close-button"
            onClick={() => setToggleExitModal(false)}
          >
            <BsX />
          </button>
          <button className="archive-button" onClick={() => saveQuiz()}>
            Save and continue
          </button>
          <Link to="/mygames">
            <button className="archive-button">Continue without saving</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuizExitModal;
