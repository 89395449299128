import './Password.scss';
import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";

const PASSWORD = "designmemo2022"

function Password(props) {

  const [passVal, setPassVal] = useState("");
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passVal === PASSWORD) {
      setInvalid(false);
      props.onSuccess();
    }
    else
      setInvalid(true);
  }

  return (
    <Modal
      show={true}
      backdrop="static"
      centered
      className="PasswordModal"
    >
      <Modal.Header>
        <Modal.Title>Password<br /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <InputGroup className="mb-3" hasValidation>
            <Form.Control type="password" placeholder="Password" value={passVal} onChange={(e) => setPassVal(e.target.value)} isInvalid={invalid} required />
            <Form.Control.Feedback type="invalid" className="invalidText">
              Wrong password, try again!
            </Form.Control.Feedback>
          </InputGroup>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button className="passButton" variant="primary" onClick={handleSubmit}>Sign in</Button>
        <p><small>For more information about the game and to request access to the site contact us at <a href="mailto:info@designmemo.se">info@designmemo.se</a></small></p>
      </Modal.Footer>
    </Modal>
  );
}

export default Password;
