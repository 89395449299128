import { db } from './firebaseConfig';
import { doc, collection, setDoc } from "firebase/firestore";
import { storage } from "apis/firebase/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

/**
* Create a game in the database
* @todo security rules in fb
* @param game A game consists of:
*
*   { createdAt :: TimeStamp
*   , createdBy :: String
*   , isFreeToPlay :: Boolean
*   , title :: String
*   , availableUntil :: TimeStamp
*   }
*/

export const createGame = async (game) => {
  try {
    // Game Images 
    const imageLinks = [];
    for (const image of game.gameImages) {
      if (image instanceof File) {
        const storageRef = ref(storage, `games/${game.uid}/${game.id}/${image.name}`);
        const snapshot = await uploadBytes(storageRef, image);
        //console.log("Uploaded a blob or file!", snapshot);
        const downloadURL = await getDownloadURL(storageRef);
        //console.log("downloadURL: ", downloadURL);
        imageLinks.push(downloadURL);
      }
      else {
        imageLinks.push(image)
      }
    }

    // Background Image
    const cardBackImage = game.backgroundImage.backgroundImage
    if (cardBackImage !== "") {
      //console.log("Uploading cardback image")
      if (cardBackImage instanceof File) {
        const storageRef = ref(storage, `games/${game.uid}/${game.id}/${cardBackImage.name}`);
        const snapshot = await uploadBytes(storageRef, cardBackImage);
        //console.log("Uploaded a blob or file!", snapshot);
        const downloadURL = await getDownloadURL(storageRef);
        //console.log("downloadURL: ", downloadURL);
        game = { ...game, backgroundImage: { ...game.backgroundImage, backgroundImage: downloadURL } }
      }
    }

    //const gameRef = doc(collection(db, "games"), game.id);

    const gameRef = doc(collection(db, "games"), game.id);
    // const colRef = collection(docRef, "userGames");
    // const gameRef = doc(colRef, game.id);

    const docRes = await setDoc(gameRef, {
      id: game.id,
      uid: game.uid,
      gameName: game.gameName,
      gameImages: imageLinks,
      backgroundImage: game.backgroundImage,
      isDraft: game.isDraft

    })

    //console.log("DocRes: ", docRes, game.id)

    // const gameRef = doc(collection(db, 'games'), game.id);
    // await setDoc(gameRef, {
    //   id: game.id,
    //   uid: game.uid,
    //   gameName: game.gameName,
    //   gameImages: imageLinks,
    //   backgroundImage: game.backgroundImage,
    //   isDraft: game.isDraft
    // });
    console.log("Image links: ", imageLinks);
    console.log("Game created in firestore: ", game);

  } catch (error) {
    console.log("Error creating game: ", error);
  }
}
