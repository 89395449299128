import {
  currentSubscription,
  getSubscriptionPlans,
  subscribe,
  visitBillingPortal,
} from "../../apis/firebase/stripe/subscriptions";
import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { CaretLeftFill } from "react-bootstrap-icons";
import memo from "../../Assets/SharedImages/logo-memo.png";
import { Link, useNavigate } from "react-router-dom";
import Plan from "./Plan";
import "./Subscription.scss";

const subscribedText = (
  <div className="info-subscribed">
    <h1>You are subscribed!</h1>
    <p>
      To manage your subscription, please visit the billing portal by clicking
      the green button below.
    </p>
    <small>Thank you!</small>
  </div>
);

const noTrialText = (
  <div className="info-wrapper">
    <h1>Get Premium Access!</h1>
    <p className="info-text">
      Gain access to all premium content for 89 kr per month. You're free to
      cancel at anytime - simply return to this page with an active
      subscription.
    </p>
    <p>Benefits include:</p>
    <ul>
      <li>Access to Svenska Memo's game collection,</li>
      <li>Creating your own games,</li>
      <li>Testing your knowledge of Swedish design</li>
    </ul>
  </div>
);

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [chosen, setChosen] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPlans() {
      const currentPriceData = await currentSubscription();
      setSubscribed(currentPriceData);

      if (!currentPriceData) {
        const fetchedPlans = await getSubscriptionPlans();
        const filteredPlans = fetchedPlans.filter((a) => a.live === "true");
        
        setPlans(filteredPlans.slice(0, 1));
        setChosen(filteredPlans[0]);
      }
      setLoading(false);
    }
    fetchPlans();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (subscribed) {
      visitBillingPortal();
    } else if (chosen.price !== "FREE") {
      subscribe(chosen);
    }
  };

  return (
    !loading && (
      <div className="subs-layout">
        <img className="logo" src={memo} alt="Logo" />
        <main className={`content ${subscribed ? "subscribed" : ""}`}>
          <div className="info">
            {subscribed
              ? subscribedText
              : noTrialText}
            {!subscribed && (
              <Link
                to="/"
                className="text-primary back-btn"
                onClick={() => navigate("/dashboard")}
              >
                <CaretLeftFill />
                To dashboard
              </Link>
            )}
          </div>
          <Form noValidate onSubmit={handleSubmit} className="form">
            {plans.map((p) => (
              <Plan
                key={p.title}
                onChange={setChosen}
                disabled={submitLoading}
                checked={chosen === p}
                plan={p}
              />
            ))}
            <Button disabled={submitLoading} variant="success" type="submit">
              {submitLoading ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : (
                "Continue"
              )}
            </Button>
            <Link to="/" className="text-primary back-btn"></Link>
            {!subscribed && (
              <Button variant="success" onClick={() => navigate("/dashboard")}>
                Continue without subscription
              </Button>
            )}
          </Form>
          {subscribed && (
            <Link
              to="/"
              className="text-primary back-btn"
              onClick={() => navigate("/dashboard")}
            >
              To dashboard
            </Link>
          )}
        </main>
      </div>
    )
  );
};

export default Subscription;
