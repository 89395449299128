import React from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { auth } from 'apis/firebase/firebaseConfig'
import { useAuthState } from 'react-firebase-hooks/auth';
import Landing from "Components/Landing";
import Layout from 'Components/Layout/Layout';
import NoMatch from "Pages/NoMatch/NoMatch";
import Login from "Pages/Login/Login";
import CreationStepper from "Pages/AccountCreation/CreationStepper";
import Dashboard from "Pages/Dashboard/Dashboard";
import Settings from "Pages/Settings/Settings";
import Edit from "Pages/Settings/Editing";
import Subscription from "Pages/Payment/Subscription";
import GameCreateSteps from "Pages/GameCreation/GameCreateSteps";
import MyGames from "Pages/MyGames/MyGames";
import GameChooser from "Pages/Games/GameChooser";
import CreateQuiz from "Pages/CreateQuiz/CreateQuiz";
import CreateShowroom from "Pages/CreateShowroom/CreateShowroom";
import CustomGame from "Pages/Games/CustomGame/CustomGame";
import Overview from "Pages/Overview/Overview";
import PrivacyPolicy from "Assets/data/PrivacyPolicy";
import CookieConsentModal from "./Components/Modals/CookieConsentModal/CookieConsentModal";
/**
* Navigate unauthorized user back to login
* @param {User} user - the un/authorized user
* @param {boolean} loading - whether the auth state is loading or not
*/
const AuthGate = ({ user, loading }) => {

  if (loading) {
    return null;
  } else if (user) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
};

/**
 * Navigate authorized user back to dashboard 
 * when entering any of the 
 * site's landing pages
 * @param {User} user - the un/authorized user
 * @param {boolean} loading - whether the auth state is loading or not
 */
const AutoDashboard = ({ user, loading }) => {

  if (loading) {
    return null;
  } else if (user) {
    return <Navigate to="/overview" replace />;
  } else {
    return <Outlet />;
  }
};


const App = () => {
  const [user, loading] = useAuthState(auth);

  return (
    <>
    <CookieConsentModal />
    <Routes>
      <Route element={<Layout />}>
        <Route element={<AutoDashboard {...{ user, loading }} />}>
          <Route path="/" element={<Landing />} />
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="new" element={<CreationStepper />} />
        <Route element={<AuthGate {...{ user, loading }} />}>
          <Route path="overview" element={<Overview />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="games">
            <Route index element={<NoMatch />} />
            <Route path=":game" element={<GameChooser />} />
          </Route>
          <Route path="settings" element={<Settings />}>
            <Route path="account" element={<Edit.Account />} />
            <Route path="subscription" element={<Edit.Subscription />} />
            <Route path="payments" element={<p>payments </p>} />
            <Route path="terms" element={<PrivacyPolicy />} />
          </Route>
          <Route path="mygames" element={<MyGames />} />
          <Route path="creategame" element={<GameCreateSteps />} />
          <Route path="createquiz" element={<CreateQuiz />} />
          <Route path="createshowroom" element={<CreateShowroom />} />
        </Route>
        <Route path="custom/:customGame" element={<CustomGame />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes >
    </>
  )
}

export default App;
