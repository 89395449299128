import { db } from './firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
} from "@firebase/firestore";

export const getQuestions = async () => {
  const questionsQueryResult = await getDocs(
    query(collection(db, "questions"), where("active", "==", true))
  );
  const questions = [];

  for (const doc of questionsQueryResult.docs) {
    if (doc.exists()) {
      questions.push(doc.data());
    }
  }
  return questions;
}
