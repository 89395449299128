import "./DashBoard.scss";
import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Card from "./GameCards";
import objects from "../../Assets/SharedImages/bakgrundrosa.png";
import Navbar, { RenderUserInfo } from "../../Components/Navbar/Navbar";
import { getClaimRole } from "../../apis/firebase/stripe/subscriptions";
import fbUtilities from "../../apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../apis/firebase/firebaseConfig";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isFreeTrialOver, setIfFreeTrialOver] = useState(false);
  const [user] = useAuthState(auth);

  useEffect(() => {
    async function getRole() {
      try {
        const role = await getClaimRole();
        const answer = await fbUtilities.isFreeTrialActive(user.uid);
        if (answer === true || role === "premium") {
          setIfFreeTrialOver(false);
        } else {
          setIfFreeTrialOver(true);
        }
      } catch (error) {
        console.error("Failed to fetch role:");
        setIfFreeTrialOver(true);
      }
    }
    getRole();
  }, []);

  return (
    <div className={`dash-layout`}>
      <Navbar />
      <main className="content">
        <img className="bg-image" src={objects} alt="objects" />
        {RenderUserInfo("desktop")}
        {isFreeTrialOver === true && (
          <Alert variant="danger" className="information-feedback">
            Your free trial or subscription has ended. Upgrade to Premium to regain access to all features. 
            <br></br>
            <Button variant="success" onClick={() => navigate("/subscription")}>
              Upgrade to Premium
            </Button>
          </Alert>
        )}
        <section className="cards">
          <h1 className="games-title">Svenska Memo Game Collection</h1>
          <Card.Memo />
        </section>
        <span className="collabs">
          Svenska Memo in collaboration with Kulturrådet and We Know IT
        </span>
      </main>
    </div>
  );
};

export default Dashboard;
