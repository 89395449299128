import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "apis/firebase/firebaseConfig";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./Settings.scss";
import {
  ArrowLeft,
  ArrowRepeat,
  CreditCard,
  FileEarmarkText,
  PersonFill,
} from "react-bootstrap-icons";
import { Accordion, Button, Card } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import Edit from "./Editing";
import PrivacyPolicy from "Assets/data/PrivacyPolicy";
import { fbSignOut } from "apis/firebase/user";

/** Body is only used in the mobile version */
const links = [
  {
    text: "Account",
    href: "account",
    disabled: false,
    Icon: PersonFill,
    Body: Edit.Account,
  },
  {
    text: "Subscription",
    href: "subscription",
    disabled: false,
    Icon: ArrowRepeat,
    Body: Edit.Subscription,
  },
  {
    text: "Terms & Conditions",
    href: "terms",
    disabled: false,
    Icon: FileEarmarkText,
    Body: PrivacyPolicy,
  },
];

const Settings = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => navigate("account"), []);

  const renderLink = ({ text, href, disabled, Icon }) => {
    return (
      <li key={text}>
        <NavLink className={`link ${disabled ? "disabled" : ""}`} to={href}>
          <Icon size={18} />
          {text}
        </NavLink>
        <hr />
      </li>
    );
  };

  const renderMobileLink = ({ text, href, disabled, Icon, Body }) => {
    return (
      <Card key={href}>
        <Accordion.Toggle
          className={`link ${disabled ? "disabled" : ""}`}
          as={Card.Header}
          eventKey={href}
        >
          <Icon size={18} />
          {text}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={href}>
          <Card.Body>
            <Body />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  return (
    <div className="settings-layout">
      <header className="bg-primary">
        <Button
          onClick={() => navigate("/dashboard")}
          className="return"
          variant="primary"
        >
          <ArrowLeft size={16} />
          Return
        </Button>
        <Button className="logOut" onClick={fbSignOut} variant="danger">
          Log out
        </Button>
        <h1>Welcome back {user.displayName}</h1>
        {/* <p>No subscriptions available</p> */}
      </header>
      <main className="settings-container">
        <nav className="menu">
          <ul className="links">{links.map(renderLink)}</ul>
        </nav>
        <Outlet />
      </main>
      <main className="settings-container-mobile">
        <nav className="menu">
          <Accordion className="links">{links.map(renderMobileLink)}</Accordion>
        </nav>
        <div className="only-desktop">
          <Outlet />
        </div>
      </main>
      <Toaster />
    </div>
  );
};

export default Settings;
