//import "./Card.scss";
import React from "react";

import { CSSTransition, SwitchTransition } from "react-transition-group";
import cards from "./../../Assets/data/cards.json";

function PlayableCard(props) {
  var card_img =
    process.env.PUBLIC_URL + "/imgs/" + cards[props.id].dirname + "/motiv.png";

  return (
    <div
      className={`Card ${props.paired ? "paired" : ""}`}
      onClick={() => props.onCardClick(props.idk, props.id)}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition timeout={325} key={props.flipped} classNames="flip">
          {props.flipped ? (
            <img src={card_img} alt={cards[props.id].name + " image"} />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/imgs/card_back.png"}
              alt="Card back"
            />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default PlayableCard;
