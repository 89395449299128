import './SideInfo.scss';
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ArrowRight } from 'react-bootstrap-icons';

import ImageCarousel from '../ImageCarousel/ImageCarousel';
import cards from '../../Assets/data/cards'
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { DataContext } from '../../contexts/DataContext';

function SideInfo(props) {
  const { state } = useContext(DataContext)
  return (
    <div className={"SideInfo"}>

      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={300}
          key={props.selectedShowRoom}
          classNames="fade"
        >
          <div>
            {
              props.selectedShowRoom != null ?
                <div>
                  <div className={`infoHolderBig ${props.selectedShowRoom != null ? "" : "hide"}`}>
                    <div className="showroomText">
                      Showroom
                    </div>
                    <img src={process.env.PUBLIC_URL + '/imgs/' + cards[props.selectedShowRoom].dirname + "/motiv.png"} alt={cards[props.selectedShowRoom].name + " image"} />

                    <h3>{cards[props.selectedShowRoom].name}</h3>
                    <h4>{cards[props.selectedShowRoom].year}</h4>
                    {
                      (cards[props.selectedShowRoom].designer.name !== "")
                        ? <div>
                          <p className="label">Designer</p>
                          <p>{cards[props.selectedShowRoom].designer.name}</p>
                        </div>
                        : ""
                    }

                    {
                      (cards[props.selectedShowRoom].producer.name !== "")
                        ? <div>
                          <p className="label">Producer</p>
                          <p>{cards[props.selectedShowRoom].producer.name}</p>
                        </div>
                        : ""
                    }
                    <div id='cardStack' className='mb-2'>
                      <div onClick={props.onShowObjectsClick} id='stackWrapper'>
                        <img className='savedCard' src={process.env.PUBLIC_URL + '/imgs/card_back.png'} alt='Card back' />
                        <img className='savedCard rotated' src={process.env.PUBLIC_URL + '/imgs/card_back.png'} alt='Card back' />
                        <p id='nbrFounds'>{state.founds.length}</p>
                      </div>
                    </div>
                    <div className="readMoreWrapper">
                      <Button id="buttonReadMoreBig" block rel="nofollow" onClick={props.onOpenShowroom}>Read more <ArrowRight className="arrowRight" size={19} /></Button>
                    </div>
                  </div>
                  <div className="infoHolderSmall">
                    <img
                      src={process.env.PUBLIC_URL + '/imgs/' + cards[props.selectedShowRoom].dirname + "/motiv.png"}
                      alt={cards[props.selectedShowRoom].name + " image"}
                    />
                    <Button
                      id="buttonReadMoreSmall"
                      block
                      rel="nofollow"
                      onClick={props.onOpenShowroom}>
                      Read more about {cards[props.selectedShowRoom].name}
                      <ArrowRight className="arrowRight" size={24} />
                    </Button>

                  </div>
                </div>

                : ""
            }{
              props.showObjects && (
                <div className={`infoHolderBig ${props.showObjects != null ? "" : "hide"}`}>
                  <ImageCarousel items={3} onClick={props.onSavedObjectClick} />
                </div>
              )
            }
          </div>
        </CSSTransition>
      </SwitchTransition>

    </div >
  );
}

/*


<p>{cards[props.selectedShowRoom].description}</p>*

*/
export default SideInfo;
