import React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AuthView from "Pages/AuthView/AuthView";
import { Formik } from "formik";
import "./AccountCreation.scss"; 

const prompt = `Sign up for free, get a free trial 48 h to access to Svenska Memo Game Collection and to create your own game. 
Within a full subscription 89 kr/month, non-binding, you will also be able to Share your game.
Cancel anytime!`;
const question = `Already have an account? Log in`;

const AccountCreation = ({ onNext, createUser, user }) => {
  const validate = (values) => {
    const errors = Object.keys(values).reduce((prev, current) => {
      if (!values[current]) {
        return { ...prev, [current]: "Required" };
      } else {
        return prev;
      }
    }, {});

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Must match";
      errors.password = " ";
    }

    if (values.password.length < 6) {
      errors.password = " ";
      errors.repeatPassword = "Password must be at least 6 characters";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    createUser(values);
    onNext();
  };

  return (
    <AuthView
      title="Create account"
      subtitle="Fill in your details to create an account"
      question={question}
      to="/login"
    >
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          name: user.name,
          email: user.email,
          password: user.password,
          repeatPassword: user.password,
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name"
                value={values.name}
                onChange={handleChange}
                isValid={touched.name && values.name}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Enter your email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                placeholder="Enter email"
                onChange={handleChange}
                isValid={touched.email && values.email && !errors.email}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
                isInvalid={touched.password && errors.password}
                isValid={touched.repeatPassword && !errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="repeatPassword">
              <Form.Label>Repeat password</Form.Label>
              <Form.Control
                type="password"
                name="repeatPassword"
                onChange={handleChange}
                value={values.repeatPassword}
                isValid={touched.repeatPassword && !errors.repeatPassword}
                isInvalid={touched.repeatPassword && errors.repeatPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.repeatPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="continue-btn" type="submit">
              Continue
            </Button>
            <span>{prompt}</span>
          </Form>
        )}
      </Formik>
    </AuthView>
  );
};

export default AccountCreation;
