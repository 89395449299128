import React from "react";
import { Modal, Button } from "react-bootstrap";

const AreYouSureModal = ({ onAccept, onDeny, display, isCustom = false }) => {

  return (
    <Modal
      show={display}
      backdrop="static"
      centered
      className="areYouSure"
    >
      <Modal.Header className="modal-header border-0" closeButton onHide={onDeny}>
        <Modal.Title>
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          If you decide to skip the card game and play the quiz right away, you will not be
          eligible to compete with your result.
        </p>
        <p>
          Do you want to skip the card game?
        </p>
        <Modal.Footer className="modal-header border-0">
          <Button onClick={onAccept} type="button" variant={isCustom ? "info" : "primary"}>
            Yes
          </Button>
          <Button onClick={onDeny} type="button" variant={isCustom ? "outline-info" : "outline-primary"}>
            No
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal >

  )
}

export default AreYouSureModal;
