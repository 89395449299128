import './Leaderboard.scss'
import { auth } from '../../../apis/firebase/firebaseConfig';
import { leaderboard } from '../../../apis/firebase/results';
import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getParsedTimer } from '../../../Utils/helpers';

const Leaderboard = ({ title, game, display, onClose }) => {
  const [scores, setScores] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    async function getScores() {
      const tops = await leaderboard(game, 5);
      setScores(tops);
    }
    getScores();
  }, [display]);


  return (
    <Modal
      show={display}
      backdrop="static"
      centered
    >
      <Modal.Header className="modal-header border-0" closeButton onHide={onClose}>
        <h1 style={{ textAlign: 'center', width: '100%' }}>
          {title}
        </h1>
      </Modal.Header>
      <Modal.Body>
        <h2 className='header2ForLeaderboard'>
          Top Scores of the Week
        </h2>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Time</th>
              <th>Moves</th>
              <th>Quiz</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {scores.map((d, i) => (
              <tr className={i % 2 == 0 ? 'bg-light' : ''} key={i}>
                <td>{i + 1}</td>
                <td>{d.alias}</td>
                <td>{getParsedTimer(d.time)}</td>
                <td>{d.moves}</td>
                <td>{Math.round(d.quiz * 100)}%</td>
                <td>{d.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal >

  )
}

export default Leaderboard
