import React from "react";
import { Link } from "react-router-dom";
import "./AuthView.scss";
import diskborste from "./diskborste.png";
import bumling from "./bumling.png";
import karaff from "./karaff.png";

const allDesigns = () => {
  return [
    {
      src: karaff,
      name: "Droppen",
      year: "1981",
      designer: "Ingegerd Råman",
      info: "En klassiker bland karaffer. En pitcher, formgiven av en av våra största glasformgivare i modern tid.",
    },
    {
      src: diskborste,
      name: "Jordan 1230",
      year: "1974",
      designer: "A&E Design AB, Hans Ehrich and Tom Ahlström",
      info: "En klassiker, har sålts i mer än 67 miljoner exemplar. Diskborsten är ergonomiskt välstuderad, både när det gäller form och funktion. Tillverkad av återvinningsbar polypropenplast.",
    },
    {
      src: bumling,
      name: "Bumling",
      year: "1968",
      designer: "Anders Pehrson",
      info: "Bumling pendel. i lackerad aluminium, är en riktig klassiker inom belysning. Bumling pendel fick sällskap av en hel familj Bumlingar under 1970-talet.",
    },
  ];
};

const AuthView = ({ title, subtitle, children, question, to }) => {
  const designs = allDesigns();
  const rand = Math.floor(Math.random() * designs.length);

  return (
    <div className="auth-layout">
      <section>
        <div className="form-wrapper">
          <header>
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </header>
          {children}
        </div>
        <span className="already-account">
          {question}{" "}
          <Link to={to}>
            <u>here</u>
          </Link>
          .
        </span>
      </section>
      <div className="bg-wrapper">
        <img rel="preload" src={designs[rand].src} className="bg-image" />
        <section className="design-info">
          <p className="title">{designs[rand].name}</p>
          <p className="year">{designs[rand].year}</p>
          <p className="designer">Designer: {designs[rand].designer}</p>
        </section>
      </div>
    </div>
  );
};

export default AuthView;
