import blackCardback from "./blackCardback.png"
import pinkCardback from "./pinkCardback.png"
import goldCardback from "./goldCardback.png"

const blobUrlToFile = (blobUrl, i) =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const file = new File([blob], `Cardback${i}`, {
          type: blob.type,
        });
        resolve(file);
      });
    });
  });

/**
 * Converts cardback presetimages to blobs to keep 
 * consistent use of type throughout the program.
 * @returns blobs of preset cardback images
 */
export async function getCardbacks() {
  const images = [blackCardback, pinkCardback, goldCardback];

  const blobs = await Promise.all(images.map((v, i) => blobUrlToFile(v, i)));
  return blobs;
};
