import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import StepIndicator from "Components/StepIndicator/StepIndicator";
import Memory from "Components/Memory/Memory";
import { createGame } from "apis/firebase/games";
import { useNavigate } from "react-router-dom";

import "./Overview.scss";

let MAX_CARDS = 12;
//let MAX_CARDS = 1; //dev

function shuffle(a) {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

const GameCreateSteps = ({ game, draft, goTo }) => {
  const navigate = useNavigate();
  const [cardList, setCardList] = useState([]);
  const [clicked, setClicked] = useState(false);

  const initAndShuffleCards = () => {
    // Set boundary
    setCardList([]);
    let cards_length = game.gameImages.length;
    //console.log(game, cards_length);

    let cardsList = [];

    if (MAX_CARDS > cards_length) MAX_CARDS = cards_length;

    // Create a list with all indexes & shuffle
    let card_indexes = shuffle(
      new Array(cards_length).fill(undefined).map((_, k) => k)
    );

    // Take MAX_CARDS from shuffled list
    for (let i = 0; i < MAX_CARDS; i++) {
      cardsList.push(card_indexes[i], card_indexes[i]);
    }

    cardsList = shuffle(cardsList);

    // info is used to uniquely identify a card for
    // storing in local storage
    setCardList(
      cardsList.map((e) => {
        return {
          id: e,
          info: { id: e, dirname: "" },
          flipped: false,
          paired: false,
        };
      })
    );
  };

  const setFlipCard = (idk, flip) => {
    let copyCardList = [...cardList];
    if (copyCardList[idk].flipped && !copyCardList[idk].paired) {
      // If the card is already flipped and not paired, flip it back
      copyCardList[idk].flipped = false;
    } else {
      // Otherwise, flip the card
      copyCardList[idk].flipped = flip;
    }
    setCardList(copyCardList);
  };

  const onCardClick = (idk, ide) => {
    setFlipCard(idk, true);
  };

  useEffect(() => {
    initAndShuffleCards();
  }, [game]);

  useEffect(() => {
    console.log(game);
  });

  const handleCreateGame = async () => {
    setClicked(true);
    try {
      await createGame(game);
      navigate("/mygames");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="create-game-layout">
      <p className="back-button" onClick={() => draft(true)}>
        &lt;-- Back to menu
      </p>
      <div className="overview-container">
        <h1 className="gamename-header">Create your memo</h1>
        {cardList.length > 1 && cardList ? (
          <Memory
            images={game.gameImages}
            cardBack={game.backgroundImage}
            {...{ onCardClick, cardList }}
          />
        ) : (
          <h1 className="info-text">
            You need to upload at least 3 motifs to see your memo!
          </h1>
        )}
        <div className="stepbtn-container">
          <StepIndicator currentStep={4} goTo={goTo} />
          <Button
            disabled={game.gameImages.length < 1 || clicked}
            className="next-btn"
            variant="outline-action"
            onClick={handleCreateGame}
          >
            Save new memo
          </Button>
        </div>
      </div>
      <div className="collab-text">
        Svenska Memo i samarbete med Kulturrådet och WeKnowIt
      </div>
    </div>
  );
};

export default GameCreateSteps;
