import { db } from "./firebaseConfig";
import {
    deleteDoc, getDocs, where, query, collection, doc
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";

export const deleteGame = async (game) => {
    // Delete Memory Images
    const storage = getStorage();
    const listRef = ref(storage, `games/${game.uid}/${game.id}`);
    console.log(listRef);

    listAll(listRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                deleteObject(itemRef);
            });
        }).catch((error) => {
            console.log(error)
        });

    const gameRef = doc(collection(db, "games"), game.id);
    await deleteDoc(gameRef);

}