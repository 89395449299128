
// Helpers

import { logEvent } from "firebase/analytics";
import { analytics } from "../apis/firebase/firebaseConfig"

export const getParsedTimer = (seconds) => new Date(seconds * 1000).toISOString().slice(14, 19);

export const logAnalyticPageView = (title) => {
  if (analytics !== null) {
    logEvent(analytics, 'page_view', {
      page_title: title
    });
  }
}

