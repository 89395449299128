import React from "react";

const UploadIcon = ({ className }) => {
  return (
    <svg
      // width="53"
      // height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M40.2358 45.8229H12.7642C11.9984 45.7918 11.2462 45.6098 10.5508 45.2874C9.85535 44.9652 9.23028 44.509 8.71134 43.9449C8.19241 43.3809 7.78978 42.72 7.52653 42.0003C7.26327 41.2804 7.14451 40.5159 7.17708 39.75V33.125C7.17708 32.6858 7.35158 32.2644 7.66219 31.9539C7.97281 31.6432 8.39407 31.4688 8.83333 31.4688C9.27261 31.4688 9.69387 31.6432 10.0045 31.9539C10.3151 32.2644 10.4896 32.6858 10.4896 33.125V39.75C10.4326 40.4057 10.6301 41.0582 11.0413 41.5721C11.4525 42.0862 12.0457 42.4221 12.6979 42.5104H40.2358C40.8882 42.4221 41.4813 42.0862 41.8925 41.5721C42.3037 41.0582 42.5011 40.4057 42.4442 39.75V33.125C42.4442 32.6858 42.6186 32.2644 42.9293 31.9539C43.2398 31.6432 43.6612 31.4688 44.1004 31.4688C44.5396 31.4688 44.961 31.6432 45.2715 31.9539C45.5822 32.2644 45.7567 32.6858 45.7567 33.125V39.75C45.8229 41.2857 45.2799 42.7851 44.2459 43.9224C43.212 45.0599 41.7708 45.7428 40.2358 45.8229Z"

      />
      <path
        d="M35.3333 19.3228C35.1158 19.3239 34.9 19.2814 34.6991 19.198C34.4981 19.1146 34.3157 18.9918 34.1629 18.837L26.5 11.1741L18.8371 18.837C18.5231 19.1296 18.1078 19.2888 17.6787 19.2813C17.2497 19.2737 16.8403 19.0999 16.5368 18.7964C16.2333 18.493 16.0595 18.0836 16.052 17.6545C16.0444 17.2254 16.2037 16.8102 16.4962 16.4962L25.3296 7.66284C25.6401 7.35268 26.061 7.17847 26.5 7.17847C26.9388 7.17847 27.3599 7.35268 27.6704 7.66284L36.5037 16.4962C36.814 16.8067 36.988 17.2277 36.988 17.6666C36.988 18.1055 36.814 18.5265 36.5037 18.837C36.3509 18.9918 36.1685 19.1146 35.9675 19.198C35.7666 19.2814 35.5508 19.3239 35.3333 19.3228Z"

      />
      <path
        d="M26.5 34.7813C26.0625 34.7756 25.6445 34.5993 25.3351 34.2899C25.0257 33.9806 24.8495 33.5625 24.8438 33.125V8.83337C24.8438 8.39411 25.0182 7.97283 25.3289 7.66223C25.6394 7.35163 26.0608 7.17712 26.5 7.17712C26.9392 7.17712 27.3606 7.35163 27.6711 7.66223C27.9818 7.97283 28.1562 8.39411 28.1562 8.83337V33.125C28.1505 33.5625 27.9743 33.9806 27.6649 34.2899C27.3555 34.5993 26.9375 34.7756 26.5 34.7813Z"

      />
    </svg>
  );
};

export default UploadIcon;
