import React from "react";

const ShowIcon = ({ className }) => {
    return (
        <svg
            // width="26"
            // height="26"
            viewBox="0 0 26 26"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M2.85033 12.2645C8.14482 9.40242 11.0065 3.38565 11.896 0.372312C11.9516 0.183719 11.8077 0 11.611 0H0.3C0.134315 0 0 0.134314 0 0.3V25.7C0 25.8657 0.134315 26 0.3 26H7.97586C8.14155 26 8.27621 25.8651 8.27245 25.6994C8.14776 20.2024 4.63923 15.5121 2.78283 13.7379C2.72261 13.6804 2.68966 13.6027 2.68966 13.5194V12.5316C2.68966 12.4199 2.75214 12.3176 2.85033 12.2645Z" />
            <path d="M23.1497 12.2645C17.8552 9.40242 14.9935 3.38565 14.104 0.372312C14.0484 0.183719 14.1923 0 14.389 0H25.7C25.8657 0 26 0.134314 26 0.3V25.7C26 25.8657 25.8657 26 25.7 26H18.0241C17.8585 26 17.7238 25.8651 17.7276 25.6994C17.8522 20.2024 21.3608 15.5121 23.2172 13.7379C23.2774 13.6804 23.3103 13.6027 23.3103 13.5194V12.5316C23.3103 12.4199 23.2479 12.3176 23.1497 12.2645Z" />
            <rect x="8" y="11" width="10" height="6" rx="0.3" />
        </svg>

    )
}

export default ShowIcon;