import "./GameFinished.scss";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Modals from '..'
import { DataContext } from "../../../contexts/DataContext";
import { Link } from "react-router-dom";

function GameFinished(props) {
  const [email, setEmail] = useState('');
  const [showPolicy, setShowPolicy] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false); 
  const { state } = useContext(DataContext);

  const year = new Date().getFullYear();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const timePoints = 100 - props.timer;
  const movePoints = 100 - (props.movesCounter * 2);
  const quizpoints = Math.round((props.quizResult / state.questions.length) * 100);
  const totalScore = Math.round(timePoints + movePoints + quizpoints);

  const getGameResults = () => ({
    time: props.timer,
    quiz: props.quizResult / state.questions.length,
    moves: props.movesCounter,
    email: email.trim(),
    game: "Design Memo",
    shareMyResult: true,
    alias: email.trim().slice(0, 2),
    score: totalScore,
  });

  const onSend = () => {
    if (emailRegex.test(email)) {
      props.onSendResult(getGameResults());
      setEmail('');
      setShowFeedback(false); // Reset feedback after successful submission
    } else {
      setShowFeedback(true); // Trigger feedback on invalid email
    }
  };

  return (
    <>
      <Modal
        show={props.display}
        backdrop="static"
        keyboard={false}
        centered
        className="GameFinished"
      >
        <Modal.Body>
          {props.quizResult !== null ? (
            <section>
              <h2>Congratulations on finishing the games!</h2>
              <section className="results">
                <div className="result">
                  <p className="resultTitle" style={{ fontSize: "1.4rem" }}> <b>Memo</b></p>
                  <div style={{ display: "flex" }}>
                    <p className="resultScore" >Time: {new Date(props.timer * 1000).toISOString().slice(14, 19)}</p>
                    <p className="resultScore">Moves: {props.movesCounter} </p>
                  </div>
                </div>
                <div className="result">
                  <p className="resultTitle"><b>Quiz</b> </p>
                  <p className="resultScore">
                    {props.quizResult}/{state.questions.length}
                  </p>
                </div>
              </section>
              {!props.isCustom &&
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>
                      <b>Enter your email to compete with your result</b>
                      <br />
                    </Form.Label>

                    <Form.Control type='email' value={email} onChange={e => setEmail(e.currentTarget.value)} placeholder='exempel@domain.se' isInvalid={showFeedback && !emailRegex.test(email)} />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                    <small className="form-text text-muted">
                    If you want to submit your result with the possibility of ending up within the top score, then submit your result. The first two letters from the submitted email address are shown in the Dashboard topscore so players can follow and see if the result possibly lands there. Remaining information is protected and never shared.
                    </small>
                  </Form.Group>
                </Form>
              }
              <section className="gameFinished-buttons">
                <Button variant={props.isCustom ? "info" : "primary"} onClick={props.onReset}>
                  Play again
                </Button>

                {!props.isCustom &&
                  <Button variant={props.isCustom ? "info" : "primary"} disabled={props.sendResultIsDisabled} onClick={onSend}>Send result</Button>
                }
                <Link to="/">
                  <Button variant={props.isCustom ? "info" : "primary"}>
                    Menu
                  </Button>
                </Link>
              </section>
            </section>
          ) : (
            <section>
              <h2>First part done!</h2>
              <p>Here are your results:</p>
              <p>
                Elapsed time:{" "}
                {new Date(props.timer * 1000).toISOString().slice(14, 19)}{" "}
              </p>
              <p> You can now do the quiz by pressing the button below.</p>
              <section className="buttons">
                {!props.noQuiz &&
                  <Button variant={props.isCustom ? "info" : "primary"} onClick={props.onPlayQuiz}>
                    Play the quiz
                  </Button>
                }
                <Button variant={props.isCustom ? "info" : "primary"} onClick={props.onReset}>
                  Restart
                </Button>
                {props.noQuiz &&
                  <Link to="/">
                    <Button variant={props.isCustom ? "info" : "primary"}>
                      Menu
                    </Button>
                  </Link>
                }
              </section>
            </section>
          )}
        </Modal.Body>
        <Modal.Footer>
          <p className='d-flex align-items-center justify-content-between w-100'>
            <small>
              &copy;Svenska Memo {year} &nbsp;
              <a href="mailto:info@svenskamemo.se" rel="nofollow" style={props.isCustom ? { color: "#374c43" } : {}}>
                info@svenskamemo.se
              </a>
            </small>
            <Button variant={`${props.isCustom ? "outline-info" : "primary"} btn-sm border-0`} onClick={() => setShowPolicy(true)}>Privacy policy</Button>
          </p>
        </Modal.Footer>
      </Modal>
      <Modals.PolicyModal display={showPolicy} onClose={() => setShowPolicy(false)}></Modals.PolicyModal>
    </>
  );
}

export default GameFinished;
