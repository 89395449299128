// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import {
  getFirestore,
} from "@firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export const functionLocation = 'europe-west2';
export const functions = getFunctions(app, functionLocation);
export const storage = getStorage(app); 

// Initialize Google Analytics based on user's choice. 
export let analytics = null;

export const initAnalytics = (shouldInit) => {
  if (shouldInit) {
    if (!analytics) {
      try {
        analytics = getAnalytics(app); // Initialize analytics
        setAnalyticsCollectionEnabled(analytics, true); // Enable data collection
        console.log("Firebase Analytics Initialized");
      } catch (error) {
        console.error("Error initializing Firebase Analytics:");
      }
    } else {
      console.log("Firebase Analytics already initialized.");
      setAnalyticsCollectionEnabled(analytics, true); // Ensure data collection is enabled
    }
  } else {
    console.log("Analytics initialization skipped based on user consent.");
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, false); // Disable data collection
      console.log("Firebase Analytics data collection disabled.");
    }
  }
};
