import React, { useState } from 'react'
import { Modal, Button, Form, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { emojis } from './emojis';
import './FeedbackModal.scss';

const FeedbackModal = ({ onSkip, onSend, display, isCustom = false }) => {
  const [rating, setRating] = useState('3');
  const [additional, setAdditional] = useState('');

  const ratings = [
    { label: 'Bad', value: '1', src: emojis.two },
    { label: 'Good', value: '2', src: emojis.four },
    { label: 'Great', value: '3', src: emojis.five },
  ]

  return (
    <Modal
      show={display} //display
      backdrop="static"
      centered
      className="feedback"
    >
      <Modal.Body className="d-flex flex-column">
        <p>
          Give us your opinion
        </p>
        <h4 className='font-weight-bold'>
          We always want to improve our games. Tell us what you think!
        </h4>
        <br />
        <ToggleButtonGroup className='mb-4 flex-wrap toggles' type="radio" name="radio">
          {ratings.map((radio, idx) => (
            <ToggleButton
              className="d-flex flex-column align-items-center"
              key={idx}
              id={`radio-${idx}`}
              variant={isCustom ? "outline-info" : "outline-primary"}
              src={radio.src}
              value={radio.value}
              checked={radio.value === rating}
              onChange={(e) => setRating(e.currentTarget.value)}
            >
              <img className='icon' src={radio.src} alt={radio.src} />
              {radio.label}
            </ToggleButton>

          ))
          }
        </ToggleButtonGroup>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Do you want to add something?</Form.Label>
            <Form.Control className='feedback-form' onChange={e => setAdditional(e.target.value)} as="textarea" rows={3} />
          </Form.Group>
        </Form>
        <Modal.Footer className="modal-header border-0">
          <Button onClick={onSkip} type="button" variant={isCustom ? "outline-info" : "outline-primary"} >
            Skip
          </Button>
          <Button onClick={onSend(rating, additional)} type="button" variant={isCustom ? "outline-info" : "outline-primary"} >
            Send
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal >
  )
}

export default FeedbackModal
