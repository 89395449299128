import React from "react";
import "./Landing.scss";
import phones from './multiplephones.png'
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import SvenskaMemoLogo from './SvenskaMemoLogo.png';

const Landing = () => {
  const navigate = useNavigate();

  return (
    <div className="landingContainer">
      <img className="memo-logo" src={SvenskaMemoLogo} alt="Logo" />
      <img className="phone" src={phones} alt="Phone screens" />
      <main className="info">
        <h1>design meets game</h1>
        <p>
          Challenge yourself or a friend. Play our design game, and make your own
          game with Premium Access.
        </p>
        <div className="btns">
          <a
            href={"https://www.svenskamemo.se"}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="px-4 m-0" type="button" variant="secondary">
              About us
            </Button>
          </a>
          <Button
            onClick={() => navigate("new")}
            className="px-4 m-0"
            type="button"
            variant="secondary"
          >
            Sign up
          </Button>
          <Button
            onClick={() => navigate("login")}
            className="px-4 m-0"
            type="button"
            variant="outline-secondary"
          >
            Log in
          </Button>
        </div>
        <small className="txt-below-btns">
          For new subscribers sign up to take advantage of a free trial 48 h.<br/>
          Play, create and share your own game with a monthly subscription.<br/>
          Premium Access 89 SEK/month non-binding.
        </small>
        <small className="read-more">
          Read more at{" "}
          <a target="_blank" href="https://www.svenskamemo.se">
            svenskamemo.se <FiExternalLink size={13} />
          </a>
        </small>
        <small className="rights">
          ©Svenska Memo AB 2024 All rights reserved
        </small>
      </main>
      <div className="right-text-container">
      <small className="collabs">
      This site includes a tribute game and design templates, to be able to create your own games.<br/>
      Expand with a showroom, add stories and complete with a quiz. Own games can be shared<br/>
      via a link. Responsive web works for example on computer and mobile. 
      </small>
      <small className="offer">
        Svenska Memo in collaboration with Swedish Arts Council and We Know it.
      </small>
      </div>
    </div>
  );
};

export default Landing;
