import { getClaimRole } from '../../../apis/firebase/stripe/subscriptions';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './Editing.scss';
import fbUtilities from "../../../apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../apis/firebase/firebaseConfig";

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    async function getRole() {
      try {
        const role = await getClaimRole();
        const answer = await fbUtilities.isFreeTrialActive(user.uid);
        if (role === "premium") {
          setRole("Premium")
          console.log("premium user")
        } else if (answer === true){
          setRole("Free Trial");
          console.log("free trial user")
        }
      } catch (error) {
        console.error("Failed to fetch role:");
        setRole(null);
      } finally {
        setLoading(false);
      }
    }
    getRole();
  }, []);

  return (
    <section className='edits-container'>
      <h2 className="title">Subscription</h2>
      <p className="tier-container">
        {!loading && (role ? role : 'Upgrade to Premium')}
        <Link to="/subscription">Manage</Link>
      </p>
      <hr /><p>To see your next billing, change your payment information or cancel your subscription, please visit the <Link to="/subscription">billing portal</Link>.</p><hr />
    </section>
  );
};

export default Subscription;
