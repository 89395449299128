import React from "react";
import objects from "Assets/SharedImages/bakgrundstart.svg";
import "./CreateGameModal.scss";
import MemoryIcon from "Assets/Icons/MemoryIcon";
import QuizIcon from "Assets/Icons/QuizIcon";

const CreateGameModal = ({ link, display, onClose }) => {
  return (
    <div
      className="create-game-modal"
      style={{ visibility: `${display ? "" : "hidden"}` }}
    >
      <div className="create-game-card">
        <img className="bg-modal-image" src={objects} alt="objects" />
        <p className="create-game-title">Skapa</p>
        <div className="leave-buttons">
          <button
            className="close-button"
            onClick={() => onClose(false)}
          ></button>
          <div className="icon-button-container">
            <div className="icon-clickable">
              <MemoryIcon className="icon-button" />
            </div>
            <div className="icon-clickable">
              <QuizIcon className="icon-button" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGameModal;
