import React, { useEffect, useState } from "react";
import Card from "../Card/Card";
import "./Memory.scss";

const Memory = ({
  cardList,
  onCardClick,
  images,
  cardBack,
  cardText,
  playable,
}) => {
  const sizes = ["six", "eight", "twelve", "sixteen", "twenty", "twentyfour"];
  // const [size, setSize] = useState(sizes[Math.floor(cardList.length / 4) - 1])
  // useEffect(() => {
  //   console.log(size, Math.floor(cardList.length / 4) - 1, cardList)
  // }, [size])
  const cardsElements = cardList.map((e, k) => {
    return (
      <Card
        key={k}
        id={e.id}
        idk={k}
        onCardClick={onCardClick}
        flipped={e.flipped}
        paired={
          e.paired
        } /*check here to adjust it back to how it worked with pairing: https://github.com/We-Know-IT/Svenska-Memo/blob/Mehir-Firebase-Storeimages/src/components/Memory/Memory.jsx */
        img={images[e.id]}
        cardBack={cardBack}
        cardText={cardText}
      />
    );
  });

  return (
    <div
      className={`cardholder ${sizes[Math.floor(cardList.length / 4) - 1]} ${
        playable ? "playable" : ""
      }`}
    >
      {cardsElements}
    </div>
  );
};

export default Memory;
