import React from "react";

const Searchicon = ({ className }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M13.0234 0C5.84191 0 0 5.84191 0 13.0234C0 20.2048 5.84191 26.0467 13.0234 26.0467C15.2932 26.0467 17.4513 25.4514 19.3118 24.4095C19.4105 24.5794 19.5225 24.7411 19.6467 24.8933L23.3677 28.7258C23.7112 29.1125 24.1302 29.4248 24.5989 29.6436C25.0675 29.8624 25.5759 29.9831 26.0929 29.9984C26.6099 30.0136 27.1246 29.923 27.6053 29.7321C28.086 29.5412 28.5226 29.2541 28.8884 28.8884C29.2541 28.5226 29.5412 28.086 29.7321 27.6053C29.923 27.1246 30.0136 26.6099 29.9984 26.0929C29.9831 25.5759 29.8624 25.0675 29.6436 24.5989C29.4248 24.1302 29.1125 23.7112 28.7258 23.3677L24.8933 19.6467C24.7411 19.5225 24.5794 19.4105 24.4095 19.3118C25.4142 17.4513 26.0467 15.2932 26.0467 13.0234C26.0467 5.84191 20.2048 0 13.0234 0ZM13.0234 3.72096C18.1955 3.72096 22.3258 7.85123 22.3258 13.0234C22.3258 15.2187 21.5816 17.2653 20.3537 18.8281C20.3165 18.8653 20.2793 18.9025 20.242 18.9397C20.1664 18.9744 20.0919 19.0116 20.0188 19.0513C19.6457 19.3368 19.3301 19.6903 19.0885 20.0932C17.4513 21.47 15.3304 22.2886 13.0234 22.2886C7.85123 22.2886 3.72096 18.1583 3.72096 12.9862C3.72096 7.81402 7.85123 3.68375 13.0234 3.68375V3.72096ZM11.1629 7.44193V11.1629H7.44193V14.8839H11.1629V18.6048H14.8839V14.8839H18.6048V11.1629H14.8839V7.44193H11.1629Z"
      />
    </svg>
  );
};

export default Searchicon;
