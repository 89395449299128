import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updatePassword, updateProfile } from "firebase/auth"
import { auth } from "./firebaseConfig"

export const fbCreateUser = async (email, password, username) => createUserWithEmailAndPassword(auth, email, password)
  .then(res => updateProfile(res.user, { displayName: username }));

export const fbSignIn = (email, password) => signInWithEmailAndPassword(auth, email, password);

export const fbSignOut = async () => await signOut(auth);

export const fbUpdateUsername = async (username) => updateProfile(auth.currentUser, { displayName: username })

export const fbUpdateEmail = async (email) => updateProfile(auth.currentUser, email);

export const fbUpdatePassword = async (password) => updatePassword(auth.currentUser, password);

export const fbUpdateAccount = async (username, email, password) => {
  const up = fbUpdateUsername(username);
  const ep = fbUpdateEmail(email);
  const pp = fbUpdatePassword(password);
  await Promise.all([up, ep, pp]);
}
