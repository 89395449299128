import React, { useState, useEffect } from "react";
import StepIndicator from "Components/StepIndicator/StepIndicator";
import DoubleCard from "./DoubleCard";
import "./UploadImages.scss";
import { Button } from "react-bootstrap";
import EditImage from "Assets/Icons/Emptycard.PNG";
import Plussign from "Assets/Icons/PlusSign";
import DeleteIcon from "Assets/Icons/Delete";
import UploadIcon from "Assets/Icons/UploadIcon";
import CropIcon from "Assets/Icons/CropImg";
import ImageCrop from "../../../Components/ImageCrop/ImageCrop";

const UploadImages = ({ game, onNext, editGame, onPrev, goTo, draft }) => {
  const MAX_PAIRS = 12;

  const [selectedFiles, setSelectedFiles] = useState(
    game.gameImages.length > 0 ? game.gameImages : [EditImage]
  );
  //const [showImg, setShowImg] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [editImage, setEditImage] = useState(false);
  const [cropped, setCropped] = useState(
    game.gameImages.length > 0
      ? Array.apply(null, Array(game.gameImages.length)).map((_) => true)
      : [false]
  );

  useEffect(() => {
    const filteredImages = selectedFiles.filter((img) => img !== EditImage);
    editGame({ ...game, gameImages: filteredImages });
  }, [selectedFiles]);

  const addNewPair = () => {
    if (selectedFiles.length < MAX_PAIRS) {
      setSelectedFiles([...selectedFiles, EditImage]);
      setCropped([...cropped, false]);

      setCurrentImage(selectedFiles.length);
    }
  };

  const updateImage = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const image = e.target.files[0];
    e.target.value = null;
    const newImages = selectedFiles.map((img, i) => {
      if (i === currentImage) {
        return image;
      }
      return img;
    });
    setSelectedFiles(newImages);
    setEditImage(true);
  };

  const getImages = (index) => {
    setCurrentImage(index);
    //setShowImg(true);
  };

  const updateCroppedImage = (croppedImage) => {
    const newImages = selectedFiles.map((img, i) => {
      if (i === currentImage) {
        cropped[i] = true;
        return croppedImage;
      }
      return img;
    });
    setSelectedFiles(newImages);

    setEditImage(false);
  };

  const removeImage = (index) => {
    if (selectedFiles.length > 1) {
      const newImages = selectedFiles.filter((_, i) => i !== currentImage);
      setCurrentImage(index === 0 ? 0 : index - 1);
      setSelectedFiles(newImages);
      setEditImage(false);
      //setShowImg(false);
    }
  };

  return (
    <div className="create-game-layout">
      <p className="back-button" onClick={() => draft(true)}>
        &lt;-- Back to menu
      </p>
      <h1 className="create-game-title">Create your memo</h1>
      <div className="edit-image-container">
        {selectedFiles[currentImage] instanceof Blob ? (
          <img
            src={URL.createObjectURL(selectedFiles[currentImage])}
            alt="selected"
            className="image-container"
          />
        ) : (
          <img
            src={selectedFiles[currentImage]}
            alt="selected"
            className="image-container"
          />
        )}
        <div className="editicons-container">
          <label className="icon-container">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => updateImage(e)}
              style={{ display: "none" }}
            />
            <UploadIcon className="upload-icon" />
          </label>
          {selectedFiles[currentImage] !== EditImage && (
            <div className="icon-container">
              <CropIcon
                className="crop-icon"
                onClick={() => setEditImage(true)}
              />
            </div>
          )}
          <div className="icon-container">
            <DeleteIcon
              className="delete-icon"
              onClick={() => removeImage(currentImage)}
            />
          </div>
        </div>
      </div>

      <div className="uploadimages-layout">
        {selectedFiles.map((image, index) => (
          <div
            key={index}
            onClick={() => {
              getImages(index);
            }}
          >
            <DoubleCard
              cardImg={image === "" ? EditImage : image}
              index={index + 1}
              key={index}
            />
          </div>
        ))}

        {selectedFiles.length !== MAX_PAIRS && (
          <div onClick={() => addNewPair()}>
            <div className="custom-box">
              <Plussign />
            </div>
            <p className="upload-text">Add pair</p>
          </div>
        )}
      </div>

      <div className="stepbtn-container">
        <StepIndicator currentStep={2} goTo={goTo} />
        <Button className="next-btn" variant="outline-action" onClick={onNext}>
          Next
        </Button>
      </div>
      {editImage && (
        <div className="cropper-container">
          <ImageCrop
            image={selectedFiles[currentImage]}
            onExit={setEditImage}
            updateImage={updateCroppedImage}
            required={cropped[currentImage]}
          />
        </div>
      )}
      <div className="collab-text">
        Svenska Memo i samarbete med Kulturrådet och WeKnowIt
      </div>
    </div>
  );
};

export default UploadImages;
