import React, { useEffect, useState } from "react";
import { FiShare2 } from "react-icons/fi";
import MemoryIcon from "../../Assets/Icons/MemoryIcon";
import ShowIcon from "../../Assets/Icons/ShowIcon";
import QuizIcon from "../../Assets/Icons/QuizIcon";
import Delete from "../../Assets/Icons/Delete";
import { DisplayCard } from "../../Components/MemoryDisplay/MemoryDisplay";
import CustomDropdown from "../../Components/CustomDropdown/CustomDropdown";
import { Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getClaimRole } from "../../apis/firebase/stripe/subscriptions";
import fbUtilities from "../../apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../apis/firebase/firebaseConfig";

function hasMemory(game) {
  return game.backgroundImage !== undefined;
}

function hasQuiz(game) {
  return game.quiz !== undefined;
}

function hasShowroom(game) {
  return game.showroom !== undefined;
}

const GamesDisplay = ({
  games,
  isLoading,
  setDisplay,
  setIsLinkPlayable,
  handleAdd,
  handleEdit,
  handleDelete,
  fetchGames,
  isPublished,
}) => {
  const [role, setRole] = useState("");
  const [user] = useAuthState(auth);

  useEffect(() => {
    async function getRole() {
      try {
        const role = await getClaimRole();
        const answer = await fbUtilities.isFreeTrialActive(user.uid);
        if (answer === true || role === "premium") {
          setRole("premium");
          console.log("answer: " + answer + " role: " + role)
        }
      } catch (error) {
        console.error("Failed to fetch role:");
        setRole("");
      }
    }
    getRole();
  }, []);

  return (
    <ListGroup className={`list-group ${role !== "premium" ? "disabled" : ""}`}>
      {games.map((g) => {
        return (
          <ListGroup.Item key={g.id} className="list-item">
            <div className="list-icon display-card">
              <Link to={`/custom/${g.id}`} state={{ game: g }}>
                <DisplayCard cardStyle={g.backgroundImage} />
              </Link>
            </div>
            <Link
              to={`/custom/${g.id}`}
              state={{ game: g }}
              className="list-title"
            >
              {g.gameName}
            </Link>
            <div className="list-interactive list-share">
              {isPublished && (
                <Button
                  variant="grey"
                  onClick={() => {
                    setDisplay(g);
                    setIsLinkPlayable(g.playable);
                  }}
                >
                  <FiShare2 /> Share
                </Button>
              )}
            </div>
            <div className="list-interactive list-edit">
              <CustomDropdown
                options={["Memory", "Quiz", "Showroom"]}
                optionsState={[!hasMemory(g), !hasQuiz(g), !hasShowroom(g)]}
                description={"Edit"}
                setCurrent={(e) => handleEdit(g, e)}
                variant="grey"
              />
            </div>
            <div className="list-interactive list-add">
              <CustomDropdown
                options={["Memory", "Quiz", "Showroom"]}
                optionsState={[hasMemory(g), hasQuiz(g), hasShowroom(g)]}
                description={"Add"}
                setCurrent={(e) => handleAdd(g, e)}
                variant="grey"
              />
            </div>
            <div className="list-delete">
              <Delete
                disabled={isLoading}
                className={"delete-icon"}
                onClick={() => handleDelete(g)}
              />
            </div>
            <div className="list-game-icons">
              <Link to={"/creategame"} state={{ game: g }}>
                <MemoryIcon
                  className={`memory-icon ${hasMemory(g) && "active"}`}
                />
              </Link>
              <Link to={"/createquiz"} state={{game: g}}>
                <QuizIcon className={`quiz-icon ${hasQuiz(g) && "active"}`} />
              </Link>
              <Link to={"/createshowroom"} state={{game:g}}>
                <ShowIcon
                  className={`show-icon ${hasShowroom(g) && "active"}`}
                />
              </Link>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default GamesDisplay;