import { createContext } from "react";
import "../apis/firebase/firebaseConfig";

export const initialState = {
  questions: [],
  founds: [],
};

export const DataContext = createContext({ initialState, dispatch: () => undefined });

