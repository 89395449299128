import './CookieConsentModal.scss';
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Nav } from "react-bootstrap";
import { initAnalytics } from "../../../apis/firebase/firebaseConfig"

const CookieConsentModal = () => {
  const [show, setShow] = useState(false);
  const [analyticsConsent, setAnalyticsConsent] = useState(true);
  const [activeTab, setActiveTab] = useState('consent'); 

  // Load consent state from localStorage and show modal if no consent is found
  useEffect(() => {
    const savedConsent = localStorage.getItem("cookieConsent");
    if (savedConsent === null) {
      setShow(true); // Show modal if no consent has been provided
    } else if (savedConsent === 'true'){
      // If consent exists, update the analytics consent state
      const consentBoolean = (savedConsent === 'true'); // Convert string to boolean
      setAnalyticsConsent(consentBoolean);
      initAnalytics(consentBoolean); // Initialize analytics if consented
    } else if (savedConsent === 'false') {
      const consentBoolean = (savedConsent === 'false'); 
      setAnalyticsConsent(consentBoolean);
      initAnalytics(consentBoolean); 
    }
  }, []);

  const handleAcceptAll = () => {
    setAnalyticsConsent(true);
    localStorage.setItem("cookieConsent", 'true'); // Set consent to true
    setShow(false); // Close modal
    initAnalytics(true); // Initialize analytics for full consent
  };

  const handleRejectAll = () => {
    setAnalyticsConsent(false);
    localStorage.setItem("cookieConsent", 'false'); 
    setShow(false); 
    initAnalytics(false); 
  };

  const handleCustomize = () => {
    const ifAllowAnalytics = analyticsConsent.toString();
    localStorage.setItem("cookieConsent", ifAllowAnalytics); 
    setShow(false); 
    initAnalytics(analyticsConsent); 
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Change the active tab
  };

  return (
    <Modal className='cookieConsentModal' show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
      <Nav variant="tabs" activeKey={activeTab} onSelect={(tab) => handleTabChange(tab)}>
        <Nav.Item>
          <Nav.Link eventKey="consent">Cookie Consent</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="about">Manage Cookies</Nav.Link>
        </Nav.Item>
      </Nav>
      <Modal.Body>
        {activeTab === 'consent' && (
          <div className="firstCookieInfo">
            <p>
              We and our partners use cookies to measure and personalize our services. You can manage your preferences and choose whether to allow these cookies in the cookie settings.
            </p>
          </div>
        )}
        {activeTab === 'about' && (
          <div className="cookieAboutInfo">
            <Form>
              <Form.Group controlId="formAnalyticsCookies">
                <Form.Check
                  type="checkbox"
                  label="Necessary Cookies"
                  checked={true}
                />
                <p>Necessary cookies enable essential features of the website, such as page navigation and account login. Without these cookies, the website cannot function properly.</p>
                <Form.Check
                  type="checkbox"
                  label="Analytics Cookies"
                  checked={analyticsConsent}
                  onChange={() => setAnalyticsConsent(!analyticsConsent)}
                />
                <p>Analytics cookies help us understand how visitors interact with our website by anonymously collecting and reporting information. This data enables us to improve both the website and the overall user experience.</p>
              </Form.Group>
            </Form>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {activeTab === 'consent' && (
          <div className="firstCookieButtons">
            <Button variant="secondary" onClick={handleRejectAll}>
              Allow necessary cookies only
            </Button>
            <Button variant="primary" onClick={handleAcceptAll}>
              Accept All
            </Button>
          </div>
        )}
        {activeTab === 'about' && (
          <Button variant="primary" onClick={handleCustomize}>
            Allow cookies
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CookieConsentModal;
