import React from "react";

const Delete = ({ className, onClick }) => {
  return (
    <svg
      // width="70%"
      // height="70%"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      <path
        d="M3.3795 14.6264L1.8 4.8H16.2L14.6205 14.6264C14.5592 15.0077 14.3452 15.3565 14.0179 15.6088C13.6905 15.8611 13.2718 16 12.8385 16H5.1615C4.72822 16 4.30947 15.8611 3.98212 15.6088C3.65478 15.3565 3.44081 15.0077 3.3795 14.6264ZM17.1 1.6H12.6V0.8C12.6 0.587827 12.5052 0.384344 12.3364 0.234315C12.1676 0.0842854 11.9387 0 11.7 0H6.3C6.0613 0 5.83239 0.0842854 5.6636 0.234315C5.49482 0.384344 5.4 0.587827 5.4 0.8V1.6H0.9C0.661305 1.6 0.432387 1.68429 0.263604 1.83431C0.0948211 1.98434 0 2.18783 0 2.4C0 2.61217 0.0948211 2.81566 0.263604 2.96569C0.432387 3.11571 0.661305 3.2 0.9 3.2H17.1C17.3387 3.2 17.5676 3.11571 17.7364 2.96569C17.9052 2.81566 18 2.61217 18 2.4C18 2.18783 17.9052 1.98434 17.7364 1.83431C17.5676 1.68429 17.3387 1.6 17.1 1.6Z"
      />
    </svg>
  );
};

export default Delete;
