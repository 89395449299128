import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ flex: 1 }}>
      <p>
        In this Privacy Policy, we refer to any information that can uniquely
        identify an individual (including the information below) as
        &ldquo;Personal Information&rdquo;. See the list below for more
        information about what Personal Information we collect and why.
      </p>
      <p>Device information</p>
      <ul>
        <li>
          <p>
            Examples of Personal Information collected:&nbsp;email address&nbsp;
          </p>
        </li>
        <li>
          <p>
            Purpose of collection:&nbsp;to identify a winner in our competition,
            communication.
          </p>
        </li>
        <li>
          <p>
            Source of collection:&nbsp;collected when submitting a result along
            with your email address. Collected from you.
          </p>
        </li>
        <li>
          <p>
            Disclosure for a business purpose:&nbsp;shared with&nbsp;Svenska
            Memo AB
          </p>
        </li>
      </ul>
      <h3>Using Personal Information</h3>
      <p>
        We use Personal Information to keep you up to date on new products,
        services, and offers.
      </p>
      <h3>User Responsibility</h3>
      <p>
        You are solely responsible for all User Content* that you publish on
        Svenska Memo. Svenska Memo does not endorse the correctness of the User
        Content or any opinion contained in any User Content.
      </p>
      <p>
        You promise that with respect to any User Content you post on Svenska
        Memo you have all rights necessary to upload such content to Svenska
        Memo and that it’s use does not violate the Svenska Memo’s policy,
        applicable law, or the intellectual property (including without
        limitation copyright), publicity, personality, or other rights of
        others.
      </p>
      <p>
        Svenska Memo may review, monitor, edit or remove User Content in our
        sole discretion, but is under no obligation to do so. In all cases,
        Svenska Memo reserves the right to remove or disable access to any User
        Content that breaches our policy. Removal or disabling of access to User
        Content shall be at our sole discretion.
      </p>
      <p>
        If you choose to upload User Content, then it is your personal
        responsibility to ensure the User Content is used in a responsible and
        ethical manner. By posting information, you agree that you will not
        upload, post, share, or otherwise distribute any content that breaches
        our policy, including;
      </p>
      <ul>
        <li>
          You are not allowed to engage, post any content or register and/or use
          a user name which includes material that is illegal, threatening,
          defamatory, abusive, harassing, degrading, intimidating, fraudulent,
          deceptive, invasive, racist, or contains any type of suggestive,
          inappropriate, or explicit language which;
        </li>
        <li>
          Infringes on any trademark, patent, trade secret, copyright, or other
          proprietary right of any party;
        </li>
      </ul>
      <p>*User Content refers to any data uploaded by you as a user.</p>
      <h3>Retention</h3>
      <p>
        Your personal information will be kept in our records unless and until
        you ask us to erase this information.&nbsp;You have the right to request
        Svenska Memo for copies of your personal data as well as correct any
        information you believe is inaccurate. You also have the right to
        restrict the processing of your personal data under certain conditions.
      </p>
      <h3>Changes</h3>
      <p>
        We may update this Privacy Policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal,
        or regulatory reasons.
      </p>
      <h3>Contact</h3>
      <p>
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us by e-mail
        at&nbsp;info@svenskamemo.se.
      </p>
      <p>Last updated: 13/11/2024</p>
    </div>
  );
};

export default PrivacyPolicy;
