import { db } from './firebaseConfig';
import { doc, writeBatch, collection, serverTimestamp } from "firebase/firestore";

export const sendFeedback = async (rating, additionalText, gameName = "Swedish Design Memo") => {

  const batch = writeBatch(db);
  const feedbackRef = doc(collection(db, 'feedback'));

  batch.set(feedbackRef, { ...{ rating, additionalText, gameName }, sentAt: serverTimestamp() });

  return await batch.commit();
}
