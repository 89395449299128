import './ShowRoom.scss';
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import cards from '../../Assets/data/cards'
import ReadMorePopup from './ReadMorePopup';

function ShowRoom(props) {
  const [showReadMorePopup, setShowReadMorePopup] = useState(false);

  function setShowReadMorePopupFalse() {
    setShowReadMorePopup(false);
  }
  function setShowReadMorePopupTrue() {
    setShowReadMorePopup(true);
  }

  return (
    <div className="showRoomZIndex">
      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={300}
          key={props.show}
          classNames="fade">
          <div>
            <ReadMorePopup show={showReadMorePopup} onClosePopup={setShowReadMorePopupFalse} id={props.id} />
            {
              props.show ?
                <section className="ShowRoom">
                  <div id='nav'>
                    <button className="backToGame" onClick={props.onClose}>
                      <ArrowLeft className="arrowRight" size={24} /> Return to the game
                    </button>
                    <p id='clock'>Paused at: {props.timer}</p>
                  </div>
                  <article className="infoContainer">
                    <section className={"mapHolder " + cards[props.id].mapPosition}
                      rel="nofollow" onClick={() => {

                        if (cards[props.id].producer.url) {
                          window.open(cards[props.id].producer.url.link, '_blank').focus()
                        }
                        else {
                          window.open(cards[props.id].designer.url.link, '_blank').focus()

                        }
                      }}
                      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/imgs/' + cards[props.id].dirname + '/map.png'})` }}>
                      <img className="miniMap" src={`${process.env.PUBLIC_URL + '/imgs/' + cards[props.id].dirname + '/map.png'}`} alt={cards[props.id].name + " map"} />
                    </section>
                    <section className="motivInfo">
                      <header>
                        <div className="showroomText">
                          Showroom
                        </div>
                        <div >
                          <h1>{cards[props.id].name}</h1>
                          <p className="yearText">{cards[props.id].year}</p>
                          <hr />
                        </div>
                        <img src={process.env.PUBLIC_URL + '/imgs/' + cards[props.id].dirname + '/motiv.png'} className="motivImg" alt={cards[props.id].name + " image"} />
                      </header>

                      <section className="overview">
                        <div>
                          <p className="label">Designer</p>
                          <p>{cards[props.id].designer.name || "-"}</p>
                        </div>
                        <div>
                          <p className="label">Producer</p>
                          <p>{cards[props.id].producer.name || "-"}</p>
                        </div>

                        <div className="overviewDescription">
                          <p>{cards[props.id].description}</p>
                        </div>
                        <div>
                          <button className="buttonReadMore" onClick={setShowReadMorePopupTrue}>More information</button>
                        </div>
                      </section>

                      <section className="description">
                        <h2 className="specificationsTitle">Description</h2>
                        <p>{cards[props.id].description}</p>

                      </section>
                      <section className="designerInfo">
                        <h2>Designer</h2>
                        <p className="textUnderTitle">{cards[props.id].designer.name}</p>

                        <p>{cards[props.id].designer.description}</p>

                        {
                          (cards[props.id].designer.url) ?
                            <Button className="button" rel="nofollow" onClick={() => (window.open(cards[props.id].designer.url.link, '_blank').focus())}>
                              {cards[props.id].designer.url.text} <ArrowRight className="arrowRight" size={19} />
                            </Button>
                            : ""
                        }

                      </section>

                      <section className="producerInfo">

                        <h2>Producer</h2>
                        <p className="textUnderTitle">{cards[props.id].producer.name}</p>

                        <p>{cards[props.id].producer.description}</p>

                        {
                          (cards[props.id].producer.url) ?
                            <Button className="button" rel="nofollow" onClick={() => (window.open(cards[props.id].producer.url.link, '_blank').focus())}>
                              {cards[props.id].producer.url.text} <ArrowRight className="arrowRight" size={19} />
                            </Button>
                            : ""
                        }
                      </section>
                      {(cards[props.id].interviews ?? []).map(i => (
                        <div key={i.url} className='interview'>
                          <h2>{i.rubric}</h2>
                          <p className="textUnderTitle">{i.info}</p>
                          <iframe title={i.rubric} src={i.url} width="fit-content" height="100%" frameBorder="0" allow="autoplay; fullscreen"></iframe>
                        </div>
                      ))
                      }
                    </section>
                  </article>
                </section>
                : ""
            }</div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}


export default ShowRoom;
