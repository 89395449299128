import React from 'react'
import { Link } from 'react-router-dom'

const NoMatch = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center flex-column align-items-center">
      <h1>Nothing to see here!</h1>
      <h2><Link to="/">Go to the home page</Link></h2>
    </div>
  )
}

export default NoMatch
