import React from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import './Layout.scss';

const Layout = () => {

  return (
    <div className="layout">
      <Outlet />
    </div>
  )
}

export default Layout
