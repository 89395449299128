import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsX } from "react-icons/bs";
import NameGame from "./NameGame/NameGame";
import UploadImages from "./UploadImages/UploadImages";
import CardBack from "./CardBack/CardBack";
import Overview from "./Overview/Overview";
import "./GameCreateSteps.scss";
import { getCardbacks } from "Assets/CardBacks/getCardbacks";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "apis/firebase/firebaseConfig";
import generateId from "Utils/generateId";
import { createGame } from "apis/firebase/games";

function getCardbackOptions(current, cardbacks) {
  const res = cardbacks.filter((image) => image === current);
  if (res.length > 0) {
    return cardbacks;
  }
  return [...cardbacks, current];
}

const GameCreateSteps = () => {
  const [user] = useAuthState(auth);
  const id = generateId(16);
  const initialGame = {
    id: id,
    uid: user.uid,
    gameName: "",
    gameDescription: "",
    gameImages: [],
    isDraft: false,
  };
  const { state } = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [game, setGame] = useState(initialGame);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [cardbacks, setCardbacks] = useState([]);

  const fetchCardbacks = useCallback(async (state) => {
    const cardbacks = await getCardbacks();
    setGame((g) => {
      if (state !== null) {
        const options = getCardbackOptions(
          state.game.backgroundImage.backgroundImage,
          cardbacks
        );
        setCardbacks(options);
        return {
          ...state.game,
        };
      }
      setCardbacks(cardbacks);
      return {
        ...g,
        backgroundImage: {
          backgroundImage: cardbacks[0],
        },
      };
    });
  }, []);

  useEffect(() => {
    fetchCardbacks(state);
  }, [fetchCardbacks, state]);

  const handleDraft = async () => {
    const draftGame = { ...game, isDraft: true };
    await createGame(draftGame);
    navigate("/mygames");
  };

  const handleNext = () => setCurrentStep(currentStep + 1);

  const handlePrev = () => setCurrentStep(currentStep - 1);

  const goTo = (step) => setCurrentStep(step);

  const steps = [
    <NameGame
      game={game}
      onNext={handleNext}
      editGame={setGame}
      goTo={goTo}
      draft={setShowDraftModal}
    />,
    <UploadImages
      game={game}
      onNext={handleNext}
      onPrev={handlePrev}
      editGame={setGame}
      goTo={goTo}
      draft={setShowDraftModal}
    />,
    <CardBack
      game={game}
      onNext={handleNext}
      onPrev={handlePrev}
      editGame={setGame}
      goTo={goTo}
      draft={setShowDraftModal}
      cardbacks={cardbacks}
      setCardbacks={setCardbacks}
    />,
    <Overview
      game={game}
      onPrev={handlePrev}
      goTo={goTo}
      draft={setShowDraftModal}
    />,
  ];

  return (
    <>
      {showDraftModal && (
        <div className="leave-modal">
          <div className="leave-card">
            {/* <img className="bg-modal-image" src={objects} alt="objects" /> */}
            <p className="leave-title">Before you leave!</p>
            <div className="leave-buttons">
              <button
                className="close-button"
                onClick={() => setShowDraftModal(false)}
              >
                <BsX />
              </button>
              <button className="archive-button" onClick={handleDraft}>
                Save your game as unpublished
              </button>
              <Link to="/mygames">
                <button className="noarchive-button">
                  Continue without saving your work
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      {steps[currentStep]}
    </>
  );
};

export default GameCreateSteps;
