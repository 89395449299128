import React from 'react';
import './LearnMoreButton.scss';
import { Button } from 'react-bootstrap';

const LearnMoreButton = () => {
  return (
    <a href='https://www.svenskamemo.com'>
      <Button type="button" className='m-0 learnMoreBtn' variant="outline-primary" >
        <span id='learnMore'>Learn more about us</span>
        <small id='evenMore'>our board games and design projects</small>
      </Button>
    </a>
  )
}

export default LearnMoreButton
