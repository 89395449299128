import './SideNav.scss';
import React from "react";
import SideItem from '../SideItem/SideItem'

import { Button } from 'react-bootstrap'
import { ArrowClockwise } from "react-bootstrap-icons";

function SideNav(props) {
  return (
    <section id="sideNav">
      <header id="titleHolder">
        {props.custom ? (
          <h2>{props.custom}</h2>
        )
          : (
            <>
              <h1>Swedish</h1>
              <h2>Design Memo</h2>
            </>
          )}
      </header>
      <section id="gameState">
        <SideItem data={props.moves} text="moves" />
        <p id="timer">{props.timer}</p>
        <SideItem data={props.points} text="points" />
      </section>
      <section id="controls">
        <Button id="controlBtn" variant="light" disabled={!props.timerStarted} onClick={props.onReset}>
          <ArrowClockwise size={19} /> Restart
        </Button>
      </section>

      {props.children}
    </section>
  );
}

export default SideNav;
