
import { db } from './firebaseConfig';
import { doc, collection, updateDoc, Timestamp } from "firebase/firestore";


export const togglePlayable = async (game) => {
    try {

        const gameRef = doc(collection(db, "games"), game.id);
        await updateDoc(gameRef, {
            playable: !game.playable,
            playableFrom: Timestamp.now().toMillis()

        })

    } catch (error) {
        console.log("Error creating game: ", error);
    }
}
