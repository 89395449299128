import React, { useState, useEffect } from "react";
import { Button, Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { List, XLg } from "react-bootstrap-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { fbSignOut } from "apis/firebase/user";
import { auth } from "apis/firebase/firebaseConfig";
import SvenskaMemoLogo from "Assets/SharedImages/SvenskaMemoLogo.png";
import "./Navbar.scss";
import { getClaimRole } from "apis/firebase/stripe/subscriptions";

export function RenderUserInfo(cm) {
  const [user] = useAuthState(auth);

  const [role, setRole] = useState("");

  useEffect(() => {
    async function getRole() {
      const role = await getClaimRole();
      setRole(role);
    }
    getRole();
  }, []);

  return (
    <Link
      className={"userInfo " + cm}
      style={{ color: "inherit" }}
      to={"/settings"}
    >
      <span className="userDot">{user.displayName.slice(0, 2)}</span>
      <span className="username">
        {user.displayName}{" "}
        <Badge variant={role === "premium" ? "warning" : "secondary"}>
          {role === "premium" ? "PREMIUM" : "FREE"}
        </Badge>
      </span>
    </Link>
  );
}

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleOpen = () => setOpen(!open);

  const renderExternal = ({ text, href, disabled }) => (
    <li key={text}>
      <a href={href} target="_blank" rel="noreferrer">
        <Button
          className={location.pathname === "/" + href ? "current" : ""}
          disabled={disabled}
          variant="outline-light"
        >
          {text}
        </Button>
      </a>
    </li>
  );

  const renderLink = ({ text, href, disabled }) => (
    <li key={text}>
      <Link to={"/" + href}>
        <Button
          className={location.pathname === "/" + href ? "current" : ""}
          disabled={disabled}
          variant="outline-light"
        >
          {text}
        </Button>
      </Link>
    </li>
  );

  return (
    <nav className="dash-nav">
      <img className="memo-logo" src={SvenskaMemoLogo} alt="Logo" />
      <div className="menu">
        <XLg
          className={open ? "show" : "hide"}
          size={"2em"}
          style={{ fill: "white" }}
          onClick={handleOpen}
        />
        <List
          className={open ? "hide" : "show"}
          size={"2em"}
          style={{ fill: "white" }}
          onClick={handleOpen}
        />
      </div>
      <div className={`nav-mobile-wrapper ${open ? "open" : ""}`}>
        <div className="links-wrapper">
          <ul className="links">
            {links
              .slice(0, -1)
              .map(renderLink)
              .concat(links.slice(-1).map(renderExternal))}
          </ul>
        </div>
        <section className="controls">
          <Button
            className="logOut"
            onClick={fbSignOut}
            variant="outline-light"
          >
            Log out
          </Button>
          {RenderUserInfo("mobile")}
        </section>
      </div>
    </nav>
  );
}

const links = [
  {
    text: "Overview",
    href: "overview",
    disabled: false,
  },
  {
    text: "Svenska Memo Game Collection",
    href: "dashboard",
    disabled: false,
  },
  {
    text: "My game collection",
    href: "mygames",
    disabled: false,
  },
  {
    text: "About us + online manuals",
    href: "https://www.svenskamemo.se",
    disabled: false,
  },
];
