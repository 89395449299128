import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import Delete from "Assets/Icons/Delete";
import fbUtilities from "apis/firebase";
import "./CreateQuiz.scss";
import { useLocation } from "react-router-dom";
import QuizExitModal from "Components/Modals/QuizExitModal/QuizExitModal";
import QuizSaveModal from "Components/Modals/QuizSaveModal/QuizSaveModal";

/**
 * Initial quiz state when making a new quiz
 */
const emptyQuiz = [
  {
    question: "",
    description: "",
    option1: "",
    option2: "",
    option3: "",
    correct: 1,
  },
  {
    question: "",
    description: "",
    option1: "",
    option2: "",
    option3: "",
    correct: 1,
  },
  {
    question: "",
    description: "",
    option1: "",
    option2: "",
    option3: "",
    correct: 1,
  },
];

const CreateQuiz = () => {
  const { state } = useLocation();

  const [toggleExitModal, setToggleExitModal] = useState(false);
  const [toggleSaveModal, setToggleSaveModal] = useState(false);

  const [toggleDesc, setToggleDesc] = useState(false);
  const [questions, setQuestions] = useState(state.game.quiz || emptyQuiz);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [correctOption, setCorrectOption] = useState(1);

  useEffect(() => {
    const q = questions[currentQuestion];
    setQuestion(q.question || "");
    if (q.description) {
      setDescription(q.description);
      setToggleDesc(true);
    } else {
      setDescription("");
      setToggleDesc(false);
    }
    setOption1(q.option1 || "");
    setOption2(q.option2 || "");
    setOption3(q.option3 || "");
    setCorrectOption(q.correct || 1);
  }, [currentQuestion, questions]);

  const addQuestion = () => {
    setQuestions([...questions, {}]);
  };

  const changeQuestion = (i) => {
    const updateQ = {
      question: question,
      description: description,
      option1: option1,
      option2: option2,
      option3: option3,
      correct: correctOption,
    };
    questions[currentQuestion] = updateQ;
    //setQuestions(questions);
    setCurrentQuestion(i);
  };

  const handleSave = async () => {
    const updateQ = {
      question: question,
      description: description,
      option1: option1,
      option2: option2,
      option3: option3,
      correct: correctOption,
    };
    questions[currentQuestion] = updateQ;
    await fbUtilities.updateQuiz({ ...state.game, quiz: questions });
    setToggleSaveModal(true);
  };

  return (
    <div className="create-quiz-layout">
      <div className="quiz-display">
        <p className="back-button" onClick={() => setToggleExitModal(true)}>
          &lt;--Back to menu
        </p>
        <div className="title-container">
          <ImArrowLeft2
            className={`arrow ${currentQuestion !== 0 ? "available" : ""}`}
            size={30}
            onClick={() => changeQuestion(Math.max(currentQuestion - 1, 0))}
          />
          <h1 className="create-quiz-title">Create your quiz</h1>
          <p className="create-quiz-title-mobile">
            Fråga {currentQuestion + 1}.{" "}
            {questions[currentQuestion].question || "Namnlös fråga"}
          </p>
          <ImArrowRight2
            className={`arrow ${
              currentQuestion !== questions.length - 1 ? "available" : ""
            }`}
            size={30}
            onClick={() =>
              changeQuestion(
                Math.min(currentQuestion + 1, questions.length - 1)
              )
            }
          />
        </div>
        {/* Display area */}
        <Form className="create-quiz-container">
          <Form.Control
            className="quiz-question-input"
            type="text"
            placeholder="Write your question here"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          {toggleDesc ? (
            <textarea
              className="quiz-description-input"
              placeholder="Add a description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          ) : (
            <Button
              variant="description"
              className="quiz-description-button"
              onClick={() => setToggleDesc(true)}
            >
              Add a description
              <AiOutlinePlus />
            </Button>
          )}
          <p className="quiz-answer-snippet">Mark the right alternative</p>
          <div className="answer-container">
            <Form.Check
              type="radio"
              id="Radio-card"
              name="radio"
              value={1}
              checked={correctOption === 1}
              onChange={(e) => setCorrectOption(Number(e.target.value))}
            />
            <Form.Control
              type="text"
              placeholder="Write an alternative"
              className="answer-input"
              value={option1}
              onChange={(e) => setOption1(e.target.value)}
            />
            <Delete className="delete-icon" onClick={() => setOption1("")} />
          </div>
          <div className="answer-container">
            <Form.Check
              type="radio"
              id="Radio-card"
              name="radio"
              value={2}
              checked={correctOption === 2}
              onChange={(e) => setCorrectOption(Number(e.target.value))}
            />
            <Form.Control
              type="text"
              placeholder="Write an alternative"
              className="answer-input"
              value={option2}
              onChange={(e) => setOption2(e.target.value)}
            />
            <Delete className="delete-icon" onClick={() => setOption2("")} />
          </div>
          <div className="answer-container">
            <Form.Check
              type="radio"
              id="Radio-card"
              name="radio"
              value={3}
              checked={correctOption === 3}
              onChange={(e) => setCorrectOption(Number(e.target.value))}
            />
            <Form.Control
              type="text"
              placeholder="Write an alternative"
              className="answer-input"
              value={option3}
              onChange={(e) => setOption3(e.target.value)}
            />
            <Delete className="delete-icon" onClick={() => setOption3("")} />
          </div>
          {/* Add more than three options - canned until late
          <Button variant="answer" className="add-answer-button">
            <AiOutlinePlus className="icon" />
          </Button> */}
        </Form>
        <Button
          variant="description"
          className="questions-button"
          onClick={() => addQuestion()}
        >
          Add question
          <AiOutlinePlus />
        </Button>
      </div>

      {/* Sidbar */}
      <nav className={`quiz-nav-container`}>
        <div className="questions-container">
          {questions.map((q, i) => {
            return (
              <Button
                variant="question"
                key={i}
                className={`add-button ${
                  currentQuestion === i ? "current" : ""
                }`}
                onClick={() => changeQuestion(i)}
              >
                <p>{i + 1}.</p>
                <p>{questions[i].question || "Place for question"}</p>
              </Button>
            );
          })}
          <Button
            variant="description"
            className="questions-button"
            onClick={() => addQuestion()}
          >
            Add a question <AiOutlinePlus />
          </Button>
        </div>
        <Button
          variant="green"
          className="add-quiz"
          onClick={() => handleSave()}
        >
          Save the quiz
        </Button>
      </nav>
      {toggleExitModal && (
        <QuizExitModal
          setToggleExitModal={setToggleExitModal}
          saveQuiz={handleSave}
        />
      )}
      {toggleSaveModal && (
        <QuizSaveModal setToggleSaveModal={setToggleSaveModal} />
      )}
    </div>
  );
};

export default CreateQuiz;
