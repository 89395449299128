import React from "react";

const Backarrow = () => {
  return (
    <svg
      width="29"
      height="19"
      viewBox="0 0 29 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.19294 0.568819C9.83224 -0.0274476 10.875 0.425901 10.875 1.30011V5.14286C10.875 5.69514 11.3286 6.14146 11.8807 6.15497C21.8689 6.39933 26.4025 10.3252 28.1186 16.4387C28.4348 17.5652 26.1947 18.2129 25.3749 17.378C22.7126 14.6665 18.9635 13.2857 14.5 13.2857H11.875C11.3227 13.2857 10.875 13.7334 10.875 14.2857V17.9496C10.875 18.8495 9.77931 19.2915 9.15491 18.6435L0.705278 9.87477C0.317636 9.47249 0.334763 8.83063 0.743305 8.44959L9.19294 0.568819Z"
        fill="#476257"
      />
    </svg>
  );
};

export default Backarrow;