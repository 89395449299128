import { db } from './firebaseConfig';
import { doc, collection, writeBatch, getDocs, orderBy, where, query, Timestamp, limit } from "firebase/firestore";


/**
* Send a result to the database
* @param result A results consists of:
*
*   { time :: Natural
*   , moves :: Natural
*   , quiz :: [0, 1]
*   , email :: String
*   , game :: String
*   }
*/
export const sendResult = async (resultData) => {
  const batch = writeBatch(db);
  const resultRef = doc(collection(db, 'results'));
  const privateRef = doc(db, `results/${resultRef.id}/private/userData`);
  const { email, ...rest } = resultData;
  batch.set(resultRef, { ...rest, sentAt: Timestamp.now() });
  batch.set(privateRef, { email: email });
  return await batch.commit();
}


export const getTodaysBestResult = async () => {
  var midnight = new Date(new Date().setHours(0, 0, 0));
  const resultRef = collection(db, 'results');
  const bestResultQuery = query(
    resultRef,
    where("sentAt", ">", Timestamp.fromDate(midnight)),
    orderBy("sentAt", "desc"),
    orderBy("time", "desc"),
    orderBy("moves", "desc"),
    orderBy("quiz", "asc"),
    limit(1)
  )
  const querySnapshot = await getDocs(bestResultQuery);
  return querySnapshot.docs[0]?.data();
}


/**
* Get the top scores for a particular game
* @param {String} game The game
* @param {Natural} n The maximum number of scores 
* 
* @returns {Promise<Array<DocumentData>>}
*/
export const leaderboard = async (game, n) => {
  const resultRef = collection(db, 'results');

  // Get the timestamp for 1 week ago
  const oneWeekAgo = Timestamp.now().toMillis() - (7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds
  const oneWeekAgoTimestamp = Timestamp.fromMillis(oneWeekAgo);

  const bestResultQuery = query(
    resultRef,
    where("game", "==", game),
    where("sentAt", ">", oneWeekAgoTimestamp),
    orderBy("sentAt"), 
    orderBy("score", "desc"), 
    limit(n)
  );
  
  const querySnapshot = await getDocs(bestResultQuery);
  
  // filter results
  const filteredResults = querySnapshot.docs
    .map((d) => d.data())
    .filter((d) => d.score >= 0);
  
  return filteredResults;
  
}