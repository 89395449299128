import one from './1.svg';
import two from './2.svg';
import three from './3.svg';
import four from './4.svg';
import five from './5.svg';

export const emojis = {
  one,
  two,
  three,
  four,
  five
}
