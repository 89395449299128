import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountCreated.scss";
import { ReactComponent as Checkmark } from "./checkmark.svg";
import fbUtilities from "../../../apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../apis/firebase/firebaseConfig";

// AccountCreated + add free trial.
const AccountCreated = () => {
  const navigate = useNavigate();
  const [theAuthUser] = useAuthState(auth);

  useEffect(() => {
    const createFreeTrial = async () => {
      if (theAuthUser) { // Ensure the user is authenticated
        try {
          const isAdded = await fbUtilities.addFreeTrialToUser(theAuthUser.uid);
          if (!isAdded) {
            console.log("Failed to add free trial.");
          } else {
            console.log("Free trial added successfully.");
          }
        } catch (error) {
          console.error("Failed to add free trial to user:");
        }
      }
    };

    const goTo = () => {
      const interval = setTimeout(() => {
        navigate("/overview"); // Navigate after adding the free trial
      }, 2000);
      
      return () => {
        clearTimeout(interval);
      };
    };

    createFreeTrial(); // Call to add free trial
    goTo(); 
  }, []);

  return (
    <div className="account-layout">
      <Checkmark />
      <p>Your account has been created!</p>
    </div>
  );
};

export default AccountCreated;
