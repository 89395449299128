import PrivacyPolicy from 'Assets/data/PrivacyPolicy';
import React from 'react';
import { Modal, Button } from "react-bootstrap";

const PolicyModal = ({ display, onClose, onAccept }) => {
  return (
    <Modal
      show={display}
      onHide={onClose}  // This only closes the modal when the "X" button is clicked
      backdrop="static"
      centered
      className="areYouSure"
    >
      <Modal.Header className="modal-header border-0" closeButton>
        <Modal.Title>
          Collecting Personal Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <PrivacyPolicy />
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="primary" onClick={onAccept}>
          I accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PolicyModal;
