import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import './BookfairModal.scss';
import Modals from '..';
import LearnMoreButton from '../../Buttons/ReadMoreButton';

//WARNING: legacy/unused
const BookfairModal = ({ onPlay }) => {
  const [msLeft, setMsLeft] = useState(new Date(2022, 8, 22).getTime() - new Date().getTime());

  const formatTime = (rem) => {
    var days = Math.floor(rem / (1000 * 60 * 60 * 24));
    var hours = Math.floor((rem % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((rem % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((rem % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds]
  }

  useEffect(() => {
    const interval = setInterval(() => setMsLeft(msLeft - 1000), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [msLeft]);

  return msLeft <= 0 ? <Modals.PlayModal onPlay={onPlay} /> :
    <Modal
      show
      backdrop={false}
      centered
    >
      <Modal.Header className="modal-header border-0" onHide={() => console.log("yo")}>
        <Modal.Title className='font-weight-bold d-flex flex-column myTitle'>
          DESIGN MEETS GAME online
          <br />
          <span id='subtitle'> Countdown to Opening Day, 22 September, 00:00</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mbody">
        <p id='countdown'>
          {['DAY', 'HOUR', 'MINUTE', 'SECOND'].map((s, i) => {
            const times = formatTime(msLeft);
            const rem = times[i];

            if (rem !== 1) {
              s = s + 'S'
            }
            return <span key={s}><span className='number'> {rem}</span> {s}{i < times.length - 1 ? ',' : ''}</span>

          })}
        </p>
        <LearnMoreButton />
        <Modal.Footer className="mt-1 modal-header border-0 p-0 justify-content-start">
          <small className='m-0'>&copy; 2022 Svenska Memo</small>
          <br />
          <small className='m-0'>in cooperation with We Know IT, supported by Swedish Arts Council and Almi</small>
        </Modal.Footer>
      </Modal.Body>
    </Modal >
}

export default BookfairModal
