import React from "react";

const QuizIcon = ({ className }) => {
  return (
    <svg
      // width="37"
      // height="38"
      viewBox="0 0 26 28"
      className={className}
      fill="none"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.24 20.2257C13.24 17.2207 15.6577 14.7846 18.64 14.7846C21.6224 14.7846 24.04 17.2207 24.04 20.2257C24.04 23.2308 21.6224 25.6668 18.64 25.6668C15.6577 25.6668 13.24 23.2308 13.24 20.2257ZM18.64 17.9405C18.0202 17.9405 17.56 18.4042 17.56 19.0287C17.56 19.3292 17.3182 19.5728 17.02 19.5728C16.7218 19.5728 16.48 19.3292 16.48 19.0287C16.48 17.8032 17.4238 16.8522 18.64 16.8522C19.8562 16.8522 20.8 17.8032 20.8 19.0287C20.8 20.0655 20.1245 20.9058 19.18 21.1399V21.1507C19.18 21.4512 18.9382 21.6948 18.64 21.6948C18.3418 21.6948 18.1 21.4512 18.1 21.1507V20.661C18.1 20.3605 18.3418 20.1169 18.64 20.1169C19.2598 20.1169 19.72 19.6532 19.72 19.0287C19.72 18.4042 19.2598 17.9405 18.64 17.9405ZM18.64 22.0213C18.9382 22.0213 19.18 22.2649 19.18 22.5654V23.0551C19.18 23.3556 18.9382 23.5992 18.64 23.5992C18.3418 23.5992 18.1 23.3556 18.1 23.0551V22.5654C18.1 22.2649 18.3418 22.0213 18.64 22.0213Z"
      />
      <path
        fill="none"
        d="M1 24.9163V1.75C1 1.33579 1.33579 1 1.75 1H20.4101C20.8243 1 21.1601 1.33579 21.1601 1.75V12.1732C21.1601 12.6054 20.7936 12.9458 20.3614 12.9433C12.159 12.8958 10.8819 20.2862 11.3274 24.7936C11.3729 25.2531 11.0214 25.6663 10.5597 25.6663H1.75C1.33579 25.6663 1 25.3305 1 24.9163Z"
        strokeWidth="1.5"
      />
      <ellipse cx="3.88016" cy="5.35248" rx="0.720002" ry="0.72548" />
      <rect
        x="5.32019"
        y="4.99004"
        width="10.08"
        height="0.72548"
        rx="0.36274"
      />
      <ellipse cx="3.88016" cy="8.25511" rx="0.720002" ry="0.72548" />
      <ellipse cx="3.88016" cy="11.156" rx="0.720002" ry="0.725481" />
      <rect
        x="5.32019"
        y="7.89267"
        width="7.20002"
        height="0.725481"
        rx="0.36274"
      />
      <rect
        x="5.32019"
        y="10.7936"
        width="7.92002"
        height="0.72548"
        rx="0.36274"
      />
    </svg>
  );
};

export default QuizIcon;
