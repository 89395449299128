import { db } from './firebaseConfig';
import { doc, collection, addDoc, Timestamp, getDocs } from "firebase/firestore";

/**
 * Adds a free trial to the customer as a subcollection. 
 * Returns if free trial was successfully added. 
 *
 * @returns boolean
 */
export const addFreeTrialToUser = async (userId) => {
  try {
    // Reference to the customer's document
    const customerRef = doc(db, "customers", userId);

    // Reference to the 'freetrials' subcollection within the customer's document
    const freetrialRef = collection(customerRef, "freetrials");

    // Add data. Use Firestore's Timestamp for better precision.
    const startTimestamp = Timestamp.now(); 
    const endTimestamp = Timestamp.fromMillis(startTimestamp.toMillis() + 48 * 60 * 60 * 1000);

    const theData = {
      trialStart: startTimestamp,
      trialEnd: endTimestamp
    };

    // Add a new document to the 'freetrials' subcollection
    const newFreeTrialRef = await addDoc(freetrialRef, theData);

    return true;
  } catch (error) {
    console.error("Failed to add free trial to user:");
    return false;
  }
};

/**
 * Checks if the customer's free trial is active. 
 * Returns if free trial is active. 
 *
 * @returns boolean
 */
export const isFreeTrialActive = async (userId) => {
  try {
    // Reference to the customer's 'freetrials' subcollection
    const customerRef = doc(db, "customers", userId);
    const freetrialRef = collection(customerRef, "freetrials");

    // Query to get all documents in the 'freetrials' subcollection (assuming there's one document)
    const freeTrialSnapshot = await getDocs(freetrialRef);

    if (freeTrialSnapshot.empty) {
      console.log("No free trial found.");
      return false; // No free trial document exists
    }

    let isActive = false;

    // Loop through the documents (even though we expect only one)
    freeTrialSnapshot.forEach((doc) => {
      const data = doc.data();
      const trialEnd = data.trialEnd;

      // Get the current time as Firestore's Timestamp
      const currentTime = Timestamp.now();

      // Check if the current time is before the trialEnd
      if (currentTime < trialEnd) {
        isActive = true; // Free trial is still active
      } else {
        isActive = false; // Free trial has ended
      }
    });

    return isActive;
  } catch (error) {
    return false; // Return false if an error occurs
  }
};
  

