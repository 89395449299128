import React, { useState, useEffect, useCallback } from "react";
import { Button, Tab, Tabs, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import "./MyGames.scss";
import Navbar, { RenderUserInfo } from "../../Components/Navbar/Navbar";
import Modals from "../../Components/Modals";
import fbUtilities from "../../apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../apis/firebase/firebaseConfig";
import GamesDisplay from "../../Components/GamesDisplay/GamesDisplay";
import { getClaimRole } from "../../apis/firebase/stripe/subscriptions";

const MyGames = () => {
  const navigate = useNavigate();
  const [shareDisplay, setShareDisplay] = useState({});
  const [user] = useAuthState(auth);
  const [publishedGames, setPublishedGames] = useState([]);
  const [unpublishedGames, setUnpublishedGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLinkPlayable, setIsLinkPlayable] = useState(false);
  const [isFullAccessOver, setIfFullAccessOver] = useState(false);

  useEffect(() => {
    async function getRole() {
      try {
        const role = await getClaimRole();
        const answer = await fbUtilities.isFreeTrialActive(user.uid);
        if (answer === true || role === "premium") {
          setIfFullAccessOver(false);
        } else {
          setIfFullAccessOver(true);
        }
      } catch (error) {
        console.error("Failed to fetch role:");
        setIfFullAccessOver(true);
      }
    }
    getRole();
  }, []);

  const fetchGames = useCallback(async () => {
    try {
      const games = await fbUtilities.getUserGames(user.uid);
      const unpub = games
        .filter((g) => g.isDraft)
        .sort((a, b) =>
          a.gameName.toUpperCase().localeCompare(b.gameName.toUpperCase())
        );
      setUnpublishedGames(unpub);
      const pub = games
        .filter((g) => !g.isDraft)
        .sort((a, b) =>
          a.gameName.toUpperCase().localeCompare(b.gameName.toUpperCase())
        );
      setPublishedGames(pub);
    } catch (e) {
      console.log("fetchgames error at mygames");
    }
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    if (Object.keys(shareDisplay).length > 0) {
      const current = publishedGames.find((g) => g.id === shareDisplay.id);
      setShareDisplay(current);
      setIsLinkPlayable(current.playable);
    }
  }, [shareDisplay, publishedGames]);

  const handleDelete = async (game) => {
    setIsLoading(true);
    await fbUtilities.deleteGame(game);
    fetchGames();
  };

  const handleEdit = (game, type) => {
    switch (type) {
      case "Memory":
        navigate("/creategame", { state: { game: game } });
        break;
      case "Quiz":
        navigate("/createquiz", { state: { game: game } });
        break;
      case "Showroom":
        navigate("/createshowroom", { state: { game: game } });
        break;
      default:
        break;
    }
  };

  const handleAdd = (game, type) => {
    switch (type) {
      case "Memory":
        navigate("/creategame", { state: {} });
        break;
      case "Quiz":
        navigate("/createquiz", { state: { game: game } });
        break;
      case "Showroom":
        navigate("/createshowroom", { state: { game: game } });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchGames();
  }, [fetchGames]);

  const handleNewGameClick = () => {
    navigate("/creategame");  // Directly navigate to create game
  };

  return (
    <div className={`dash-custom-layout`}>
      <Navbar />
      <main className="content">
        {RenderUserInfo("desktop")}
        {isFullAccessOver === true && (
          <Alert variant="danger" className="information-feedback">
            Your free trial or subscription has ended. Upgrade to Premium to regain access to all features.
            <br></br>
            <Button variant="success" onClick={() => navigate("/subscription")}>
              Upgrade to Premium
            </Button>
          </Alert>
        )}
        <div className="my-games-container">
          <div>
            <p className="my-games-title">My games</p>
            <Button
              variant="green"
              className={`my-games-button ${isFullAccessOver === true ? "disabled" : ""}`}
              onClick={() => handleNewGameClick()}
            >
              <AiOutlinePlus className="plus-icon" /> 
              Create new game
            </Button>
          </div>
          <div>
            <Tabs defaultActiveKey={"published"} id="my-games-tab">
              <Tab eventKey={"published"} title="Published">
                {publishedGames.length > 0 ? (
                  <GamesDisplay
                    games={publishedGames}
                    isLoading={isLoading}
                    setDisplay={setShareDisplay}
                    setIsLinkPlayable={setIsLinkPlayable}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    fetchGames={fetchGames}
                    isPublished={true}
                  />
                ) : (
                  <div>
                    <p className="noGamesText">
                      Click on "Create new game" to start filling out this space with your games.
                    </p>
                  </div>
                )}
              </Tab>
              <Tab eventKey={"archive"} title="Unpublished">
                {unpublishedGames.length > 0 ? (
                  <GamesDisplay
                    games={unpublishedGames}
                    isLoading={isLoading}
                    setDisplay={setShareDisplay}
                    setIsLinkPlayable={setIsLinkPlayable}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    fetchGames={fetchGames}
                    isPublished={false}
                  />
                ) : (
                  <div>
                    <p className="noGamesText">
                      There are no unpublished games at the moment - click on "Create new game" to get started.
                    </p>
                  </div>
                )}
              </Tab>
            </Tabs> 
          </div>
          <Modals.ShareLink
            link={`${window.location.href.slice(0, -7)}custom/${shareDisplay.id}`}
            display={shareDisplay}
            onClose={() => setShareDisplay("")}
            fetchGames={fetchGames}
            isPlayable={isLinkPlayable}
          />
        </div>
      </main>
    </div>
  );
};

export default MyGames;
