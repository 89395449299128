import React, { useEffect, useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import './LoginModal.scss';
import { leaderboard, sendResult } from 'apis/firebase/results';

const LoginModal = () => {
  const navigate = useNavigate();
  const [scores, setScores] = useState([]);

  useEffect(async () => {
    const tops = await leaderboard("memory", 4);
    setScores(tops);
  }, [])

  return (
    <Modal
      show
      backdrop={false}
      centered
    >
      <Modal.Header className="modal-header border-0 login-header">
        {/* <Button onClick={() => sendResult({ */}
        {/*   time: 60 + Math.floor(Math.random() * 100), */}
        {/*   moves: Math.floor(Math.random() * 100), */}
        {/*   quiz: Math.random(), */}
        {/*   game: "memory", */}
        {/*   email: 'TEST', */}
        {/* })}>Skapa "Test"</Button> */}
        {/* <p> */}
        {/*   {scores.map(JSON.stringify)} */}
        {/* </p> */}
        <Modal.Title className='font-weight-bold d-flex flex-column'>
          DESIGN MEETS GAME
        </Modal.Title>
        <strong>Welcome!</strong>
        <br />
        <p>
          To our interactive games!
        </p>
      </Modal.Header>
      <Modal.Body id='login-body'>
        <Modal.Footer style={{ gap: 20 }} className="modal-header border-0 p-0 justify-content-start">
          <div className='btn-choices'>
            <Button onClick={() => navigate("login")} className='px-4 m-0' type="button" variant="primary" >
              Log in
            </Button>
            or
            <Button onClick={() => navigate("new")} className='px-4 m-0' type="button" variant="outline-primary" >
              Create account
            </Button>
          </div>
          <small>&copy; 2022 Svenska Memo in cooperation with We Know IT, supported by Swedish Arts Council and Almi</small>
        </Modal.Footer>
      </Modal.Body>
    </Modal >
  )
}

export default LoginModal
