import './SideItem.scss';
import React from "react";

function SideItem(props) {
  return (
    <div className="SideItem">
      <p>{props.data}</p>
      <p>{props.text}</p>
    </div>
  );
}

export default SideItem;
