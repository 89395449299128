import React from "react";
import { BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import objects from "Assets/SharedImages/bakgrundstart.svg";

const QuizSaveModal = ({ setToggleSaveModal }) => {
  return (
    <div className="leave-modal">
      <div className="leave-card">
        {/* <img className="bg-modal-image" src={objects} alt="objects" /> */}
        <p className="leave-title">Your quiz is finished!</p>
        <div className="leave-buttons">
          <button
            className="close-button"
            onClick={() => setToggleSaveModal(false)}
          >
            <BsX />
          </button>
          {/* <Link to="/">
            <button className="archive-button">Testkör ditt nya quiz</button>
          </Link> */}
          <Link to="/mygames">
            <button className="noarchive-button">Back to my games</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuizSaveModal;
