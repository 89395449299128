import React, { useState } from "react";
import AccountCreated from "./Created/AccountCreated";
import AccountCreation from "./Creation/AccountCreation";
import Usernaming from "./Usernaming/Usernaming";
import Subscription from "Pages/Payment/Subscription";

const initialUser = {
  name: "",
  email: "",
  password: "",
};

const CreationStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [user, setUser] = useState(initialUser);

  const handleNext = () => setCurrentStep(currentStep + 1);

  const handlePrev = () => setCurrentStep(currentStep - 1);

  const steps = [
    <AccountCreation onNext={handleNext} user={user} createUser={setUser} />,
    <Usernaming user={user} onPrev={handlePrev} onNext={handleNext} />,
    <AccountCreated />,
  ];

  return steps[currentStep];
};

export default CreationStepper;
