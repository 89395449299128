import Modals from "../../../Components/Modals";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { AwardFill, PlayFill } from "react-bootstrap-icons";
import "./GameCard.scss";
import { getClaimRole } from "../../../apis/firebase/stripe/subscriptions";
import fbUtilities from "../../../apis/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../apis/firebase/firebaseConfig";

const GameCard = ({
  game,
  src,
  title,
  leaderboardTitle,
  description,
  optional,
  disclaimer,
  onPlay,
}) => {
  const [display, setDisplay] = useState(false);
  const [role, setRole] = useState("");
  const [user] = useAuthState(auth);

  useEffect(() => {
    async function getRole() {
      try {
        const role = await getClaimRole();
        const answer = await fbUtilities.isFreeTrialActive(user.uid);
        if (answer === true || role === "premium") {
          setRole("premium");
        }
      } catch (error) {
        console.error("Failed to fetch role:");
        setRole("");
      }
    }
    getRole();
  }, []);

  return (
    <Card className={`gameCard ${role !== "premium" ? "disabled" : ""}`}>
      <Card.Body>
        <img src={src} alt="Card background" />
        <div className="card-info">
          <p className="text">
            <span className="title">{title}</span>
            <span>{description}</span>
            <span>{optional}</span>
            <em>{disclaimer}</em>
          </p>
          <div className="buttons-wrapper">
            <Button className="play-button" variant="primary" onClick={onPlay}>
              Play
              <PlayFill size={18} />
            </Button>
            <Button
              className="leaderboard-button"
              variant="outline-primary"
              onClick={() => setDisplay(true)}
            >
              Top Scores
              <AwardFill size={18} />
            </Button>
          </div>
        </div>
        <Modals.Leaderboard
          title={leaderboardTitle}
          game={game}
          display={display}
          onClose={() => setDisplay(false)}
        />
      </Card.Body>
    </Card>
  );
};

export default GameCard;
